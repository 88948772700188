// @ts-nochec k
import { createAsyncThunk } from "@reduxjs/toolkit";

// utils
import { intl } from "lib/locale";
import { history } from "utils/history";
import { message } from "antd";

// types
import { ThunkAPI } from "types/type-def";
import {
  DayOff,
  DayOffBase,
  DayOffUpdate,
  Calendar,
  MonthWeekends,
  WorkingSchedule,
  WorkingScheduleBase,
  AllUsersInfo,
  SingleUserInfo,
  UpdatedUser,
} from "types";

// Возвращает информацию о текущем пользователе.
export const getCurrentUser = createAsyncThunk<
  SingleUserInfo,
  undefined,
  ThunkAPI
>("users/getCurrentUser", async (_, { extra: { api }, rejectWithValue }) => {
  try {
    const currentUser = await api.users.getUserInfo({});

    return currentUser;
  } catch (error) {
    console.error(error);
    return rejectWithValue(error);
  }
});

// Возвращает информацию о пользователе.
export const getUser = createAsyncThunk<
  SingleUserInfo,
  {
    id: string;
  },
  ThunkAPI
>("users/getUser", async ({ id }, { extra: { api }, rejectWithValue }) => {
  try {
    const currentUser = await api.users.getUserInfo({ id });

    return currentUser;
  } catch (error) {
    console.error(error);
    return rejectWithValue(error);
  }
});

// Возвращает информацию о всех пользователях
export const getUsers = createAsyncThunk<
  AllUsersInfo[],
  boolean | undefined,
  ThunkAPI
>(
  "users/getUsers",
  async (add_unactive, { extra: { api }, rejectWithValue }) => {
    try {
      const data = await api.users.getUsers(add_unactive);

      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

// Обновляет пользователя
export const updateUser = createAsyncThunk<
  SingleUserInfo,
  UpdatedUser,
  ThunkAPI
>(
  "users/updateUser",
  async (updateData, { extra: { api }, rejectWithValue }) => {
    try {
      await api.users.updateUser(updateData);
      const user = await api.users.getUserInfo({
        id: updateData.user_id?.toString(),
      });

      message.success(
        intl.formatMessage({
          id: "success.updated-success",
          defaultMessage: "Updated success",
        })
      );

      return user;
    } catch (error) {
      message.error("Error");
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

// создает пользователя
export const createUser = createAsyncThunk<null, Record<string, any>, ThunkAPI>(
  "users/createUser",
  async (
    {
      username,
      name,
      surname,

      phone,
      roles,
      password,
      email,
      schedule,
      techcell,
      access,
    },
    { extra: { api }, rejectWithValue }
  ) => {
    let toCreateData: Record<string, any> = {
      username,
      fullname: `${surname} ${name}`,
      roles,
      password,
      email,
      phone: phone ? phone : null,

      access_all_partners: access,
    };

    if (roles.includes("3")) {
      toCreateData.wrk_sch_id = parseInt(schedule, 10);
      toCreateData.main_tc_id = parseInt(techcell, 10);
    }

    try {
      await api.users.createUser(toCreateData);

      message.success(
        intl.formatMessage({
          id: "success.created-success",
          defaultMessage: "Created success",
        })
      );

      history.goBack();

      return null;
    } catch (error) {
      message.error("Error");
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

// Выдаёт список доступных производственных календарей
export const getCalendars = createAsyncThunk<
  Array<Calendar>,
  undefined,
  ThunkAPI
>("users/getCalendars", async (_, { extra: { api }, rejectWithValue }) => {
  try {
    const data = await api.users.getCalendars();

    return data;
  } catch (error) {
    console.error(error);
    return rejectWithValue(error);
  }
});

// Создание нового производственного календаря
export const createCalendar = createAsyncThunk<null, FormData, ThunkAPI>(
  "users/createCalendar",
  async (formdata, { extra: { api }, rejectWithValue, dispatch }) => {
    try {
      await api.users.createCalendar(formdata);
      dispatch(getCalendars());

      message.success(
        intl.formatMessage({
          id: "success.created-success",
          defaultMessage: "Created success",
        })
      );

      return null;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

// Проверка производственного календаря на корректность
// Возвращается календарь в случае если проверка не выявила ошибок,
// либо Status с описанием ошибки
export const сheckCalendar = createAsyncThunk<
  Record<string, Record<string, MonthWeekends>>,
  FormData,
  ThunkAPI
>("users/сheckCalendar", async (file, { extra: { api }, rejectWithValue }) => {
  try {
    const data = await api.users.сheckCalendar(file);

    return data;
  } catch (error) {
    console.error(error);
    return rejectWithValue(error);
  }
});

// Изменение производственного календаря
export const updateCalendar = createAsyncThunk<
  null,
  {
    calendar_id: string;
    file?: FormData;
    name: FormData;
  },
  ThunkAPI
>(
  "users/updateCalendar",
  async (
    { calendar_id, file, name },
    { extra: { api }, rejectWithValue, dispatch }
  ) => {
    const formdata = name ? name : file!;
    try {
      await api.users.updateCalendar({ calendar_id, formdata });
      dispatch(getCalendars());

      message.success(
        intl.formatMessage({
          id: "success.updated-success",
          defaultMessage: "Updated success",
        })
      );

      return null;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

// Удаление календаря
export const deleteCalendar = createAsyncThunk<number, number, ThunkAPI>(
  "users/deleteCalendar",
  async (id, { extra: { api }, rejectWithValue }) => {
    try {
      await api.users.deleteCalendar(id);

      return id;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

// список графиков работы
export const getWorkingSchedules = createAsyncThunk<
  Array<WorkingSchedule>,
  string | undefined,
  ThunkAPI
>(
  "users/getWorkingSchedules",
  async (partner_code, { extra: { api }, rejectWithValue }) => {
    try {
      const data = await api.users.getWorkingSchedules(partner_code);

      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

// создание рабочего графика
export const createWorkingSchedule = createAsyncThunk<
  null,
  WorkingScheduleBase,
  ThunkAPI
>(
  "users/createWorkingSchedule",
  async (body, { extra: { api }, rejectWithValue }) => {
    try {
      await api.users.createWorkingSchedule(JSON.stringify(body));

      message.success(
        intl.formatMessage({
          id: "success.created-success",
          defaultMessage: "Created success",
        })
      );

      window.history.back();

      return null;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

// удаление рабочего графика
export const deleteWorkingSchedule = createAsyncThunk<number, number, ThunkAPI>(
  "users/deleteWorkingSchedule",
  async (working_schedule_id, { extra: { api }, rejectWithValue }) => {
    try {
      await api.users.deleteWorkingSchedule(working_schedule_id);

      message.success(
        intl.formatMessage({
          id: "success.deleted-success",
          defaultMessage: "Deleted success",
        })
      );

      return working_schedule_id;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

// обновление рабочего графика
export const updateWorkingSchedule = createAsyncThunk<
  number,
  {
    working_schedule_id: number;
    body: WorkingScheduleBase;
  },
  ThunkAPI
>(
  "users/updateWorkingSchedule",
  async (
    { working_schedule_id, body },
    { extra: { api }, rejectWithValue }
  ) => {
    try {
      await api.users.updateWorkingSchedule({ working_schedule_id, body });

      message.success(
        intl.formatMessage({
          id: "success.updated-success",
          defaultMessage: "Updated success",
        })
      );

      return working_schedule_id;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

// список отпуска и отгулы
export const getDaysOff = createAsyncThunk<
  Array<DayOff>,
  { to_day: string; from_day: string },
  ThunkAPI
>(
  "users/getDaysOff",
  async (
    { to_day, from_day },
    { extra: { api }, rejectWithValue, getState }
  ) => {
    const tc_id = getState().orders.currentTechCell;
    try {
      const data = await api.users.getDaysOff({ to_day, from_day, tc_id });

      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

// Создаем отпуск/отгул
export const createDayOff = createAsyncThunk<null, DayOffBase, ThunkAPI>(
  "users/createDayOff",
  async (body, { extra: { api }, rejectWithValue }) => {
    try {
      await api.users.createDayOff(JSON.stringify(body));

      message.success(
        intl.formatMessage({
          id: "success.created-success",
          defaultMessage: "Created success",
        })
      );

      window.history.back();
      return null;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

// Удаляем отпуск/отгул
export const deleteDayOff = createAsyncThunk<number, number, ThunkAPI>(
  "users/deleteDayOff",
  async (day_off_id, { extra: { api }, rejectWithValue }) => {
    try {
      await api.users.deleteDayOff(day_off_id);

      return day_off_id;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

// Обновляем отпуск/отгул
export const updateDayOff = createAsyncThunk<
  DayOffUpdate & { id: number },
  { day_off_id: number; body: DayOffUpdate },
  ThunkAPI
>(
  "users/updateDayOff",
  async ({ day_off_id, body }, { extra: { api }, rejectWithValue }) => {
    const updateData = JSON.stringify(body);
    try {
      await api.users.updateDayOff({
        day_off_id,
        body: updateData,
      });

      message.success(
        intl.formatMessage({
          id: "success.updated-success",
          defaultMessage: "Updated success",
        })
      );

      history.push("/users/days-off");

      return { ...body, id: day_off_id };
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

// Список доступных пользователю методов
export const getRoutes = createAsyncThunk<
  Record<string, boolean>,
  undefined,
  ThunkAPI
>("users/getRoutes", async (_, { extra: { api }, rejectWithValue }) => {
  try {
    const data = await api.users.getRoutes();

    return data;
  } catch (error) {
    console.error(error);
    return rejectWithValue(error);
  }
});
