/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum FilterOrders {
    ALL = 'all',
    NOT_SUPPLIED = 'not_supplied',
    PLANNED = 'planned',
    PACKED = 'packed',
    ASSEMBLED = 'assembled',
}
