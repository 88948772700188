import { createSlice } from "@reduxjs/toolkit";
import { intl } from "lib/locale";

//actions
import { getTechCells, deleteTechCell } from "../actions";

//types
import { TechcellGetResponse } from "types";
import { IError } from "types/type-def";

interface IInitialState {
  techCells: TechcellGetResponse[];
  loading: boolean;
  error: IError;
}

const initialState: IInitialState = {
  techCells: [],

  loading: false,
  error: null,
};

export const avaliableTechCellsSlice = createSlice({
  name: "avaliableTechCells",
  initialState,
  reducers: {
    cleanTechCells: (state) => {
      state.techCells = [];
    },
  },
  extraReducers: (builder) => {
    // Загружаем список заказов покупателей
    builder.addCase(getTechCells.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getTechCells.fulfilled, (state, action) => {
      state.loading = false;
      state.techCells = action.payload.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
    });
    builder.addCase(getTechCells.rejected, (state) => {
      state.loading = false;
      state.error = intl.formatMessage({
        id: "error.loading-error",
        defaultMessage: "An error occurred while loading",
      });
    });

    // Удаляет ТЯ
    builder.addCase(deleteTechCell.fulfilled, (state, action) => {
      state.techCells = action.payload;
    });
  },
});
