import { useState } from "react";
import { useLocation } from "react-router-dom";

// components
import { Menu } from "antd";

// utils
import { MenuItems } from "./MenuItems";

// style
import style from "../MainMenu/MainMenu.module.css";

export const SuperAdminMenu: React.FC = () => {
  const location = useLocation();

  const [selectedKeys, setSelectedKeys] = useState([location.pathname]);

  const syncKeys = ["synchronize"];

  const onKeyClick = ({ key }: { key: string }) => {
    if (syncKeys.includes(key)) {
      return null;
    }
    setSelectedKeys([key]);
  };

  return (
    <Menu
      theme="dark"
      mode="inline"
      onClick={onKeyClick}
      selectedKeys={selectedKeys}
      className={style.menu}
      items={MenuItems()}
    />
  );
};
