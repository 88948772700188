import { createSlice, current } from "@reduxjs/toolkit";
import { intl } from "lib/locale";

//actions
import { getCalendars, сheckCalendar, deleteCalendar } from "../actions";

//types
import { IError, Maybe } from "types/type-def";
import { Calendar, MonthWeekends } from "types";

interface IInitialState {
  calendars: Array<Calendar>;
  currentCalendar: Maybe<Record<string, Record<string, MonthWeekends>>>;
  loading: boolean;
  error: IError;
}

const initialState: IInitialState = {
  calendars: [],
  currentCalendar: null,
  loading: false,
  error: null,
};

export const calendarsSlice = createSlice({
  name: "calendars",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Выдаёт список доступных производственных календарей
    builder.addCase(getCalendars.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getCalendars.fulfilled, (state, action) => {
      state.loading = false;
      state.calendars = action.payload;
    });
    builder.addCase(getCalendars.rejected, (state) => {
      state.loading = false;
      state.error = intl.formatMessage({
        id: "error.loading-error",
        defaultMessage: "An error occurred while loading",
      });
    });

    // Проверка производственного календаря на корректность
    builder.addCase(сheckCalendar.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(сheckCalendar.fulfilled, (state, action) => {
      state.loading = false;
      state.currentCalendar = action.payload;
    });
    builder.addCase(сheckCalendar.rejected, (state) => {
      state.loading = false;
      state.error = intl.formatMessage({
        id: "error.loading-error",
        defaultMessage: "An error occurred while loading",
      });
    });

    // Удаление календаря
    builder.addCase(deleteCalendar.fulfilled, (state, action) => {
      state.loading = false;
      state.calendars = current(state.calendars).filter(
        (calendar) => calendar.id !== action.payload
      );
    });
  },
});
