import { useSelector } from "app/hooks";

export const useRoutes = (route: string) => {
  const routes = useSelector((state) => state.routes?.routes);

  return routes[route];
};

export const RoutesEnum = {
  GET_CLIENTS_ORDERS: "get/api/v1/clients-orders",
  GET_ORDERS: "get/api/v1/production-queue",
  GET_STORED_PRODUCTS: "get/api/v1/stored-products",
  GET_SHIPMENTS: "get/api/v1/shipments",
  GET_CELLS: "get/api/v1/cells",
  GET_WAREHOUSES: "get/api/v1/warehouses",
  GET_PRINT_INFO: "get/api/v1/print-info",
  GET_REPORTS_SALARY: "get/api/v1/reports/salary",
  GET_WORKING_TIME: "get/api/v1/reports/users-working-time",
  GET_TECHCELL_CALENDAR: "get/api/v1/techcell-calendar",
  GET_STARTED_TASKS: "get/api/v1/started-tasks",
  GET_USERS: "get/api/v1/users",
  GET_DAYS_OFF: "get/api/v1/days-off",
  GET_WORK_SHEDULES: "get/api/v1/work-schedules",
  GET_CALENDARS: "get/api/v1/calendars",
  POST_SEMIPRODUCTS: "post/api/v1/order/{order_uuid}/semiproducts",
  POST_START_PROD_ORDER: "post/api/v1/start-production-order",
  GET_ORDER: "get/api/v1/order/{order_id}",
  GET_PROD_ORDER: "get/api/v1/production-order/{task_id}",
  GET_USER_TASKS: "get/api/v1/user-tasks/{user_id}",
  GET_ROLES: "get/api/v1/availible-roles",
  POST_CELLS: "post/api/v1/cells",
  PATCH_CELLS: "patch/api/v1/{warehouse_id}/cells/{cell_code}",
  POST_SHIPMENTS: "post/api/v1/shipments",
  GET_STORED_PRODUCT: "get/api/v1/stored-product",
  GET_SHIPMENT_INFO: "get/api/v1/shipments/{order_uuid}/{shipment_id}/info",
  POST_CALENDARS: "post/api/v1/calendars",
  POST_DAYS_OFF: "post/api/v1/days-off",
  PATCH_DAYS_OFF: "patch/api/v1/days-off/{day_off_id}",
  PATCH_CALENDARS: "patch/api/v1/calendars/{calendar_id}",
  PATCH_WORKING_SCHEDULE: "patch/api/v1/work-schedules/{working_schedule_id}",
  POST_SUBMIT_PRODUCTION: "post/api/v1/submit-production",
  GET_SEMIPRODUCTS_REPORT: "get/api/v1/semiproducts-by-tasks/{tc_id}",
  GET_PACKAGES: "get/api/v1/packages",
  POST_CONSOLIDATION_TASK: "post/api/v1/consolidation-task",
  GET_FREE_COMPONENTS: "get/api/v1/free-components/{tc_id}",
  GET_TC_USERS: "get/api/v1/techcell-users",
  GET_TECHCELLS: "get/api/v1/techcells",
  DELETE_SHIPMENT: "delete/api/v1/shipment/{shipment_id}",
  POST_CLOSE_SHIPMENT: "post/api/v1/shipments/{shipment_id}/close-shipment",
  PATCH_CHANGE_REPLACEMENT:
    "patch/api/v1/change-replacement/{replacement_uuid}",
  GET_SHIPMENT_REPORT: "get/api/v1/reports/shipment/{shipment_id}/packages",
  PUT_PAUSE_PRODUCTION: "put/api/v1/pause-production",
  PUT_APPROVE_STATE: "put/api/v1/order/{order_id}/approve-state",
  POST_LAUNCH_ORDER: "post/api/v1/production-order-from-customer-order",
  GET_CLIENT_ORDERS_COMPONENTS: "get/api/v1/client-order-components",
  GET_PRODUCTION_ORDERS_COMPONENTS: "get/api/v1/production-order-components",
  POST_CREATE_ORDER: "post/api/v1/create-order",
  DELETE_ORDER: "delete/api/v1/order/{order_id}",
  PUT_ORDER: "put/api/v1/order/{order_id}",
  POST_CREATE_USER: "post/api/v1/create-user",
  GET_AVAILABLE_ROLES: "get/api/v1/availible-roles",
  POST_WORK_SCHEDULES: "post/api/v1/work-schedules",
  GET_QUANTITY: "get/api/v1/get_quantity",
  GET_PRODUCED_PRODUCTS: "get/api/v1/produced-products/report",
  GET_COUNTERPARTY: "get/api/v1/counterparty",
  PUT_COUNTERPARTY: "put/api/v1/counterparty/{counterparty_id}",
  GET_NOMENCLATURE_SPEC: "get/api/v1/nomenclature/{product_id}/spec",
};

export const MainMenuArr = (routes: Record<string, boolean>) => {
  const menu: Record<string, Record<string, boolean>> = {
    orders: {
      "/orders/customer-orders": routes[RoutesEnum.GET_CLIENTS_ORDERS],
      "/orders/production-orders": routes[RoutesEnum.GET_ORDERS],
      "/orders/production-schedule": routes[RoutesEnum.GET_ORDERS],
    },
    materials: {
      "/orders/fulfillment": routes[RoutesEnum.GET_CLIENTS_ORDERS],
      "/orders/semiproducts": routes[RoutesEnum.GET_STORED_PRODUCTS],
      "/storage/products": routes[RoutesEnum.GET_STORED_PRODUCTS],
      "/orders/rest-materials": routes[RoutesEnum.GET_FREE_COMPONENTS],
    },
    storage: {
      "/storage/shipments": routes[RoutesEnum.GET_SHIPMENTS],
      "/storage/cells": routes[RoutesEnum.GET_WAREHOUSES],
      "/storage/printing-labels": routes[RoutesEnum.GET_PRINT_INFO],
    },
    reports: {
      "/reports/production-salary":
        routes[RoutesEnum.GET_REPORTS_SALARY] &&
        routes[RoutesEnum.GET_TECHCELLS] &&
        routes[RoutesEnum.GET_USERS],
      "/reports/working-time": routes[RoutesEnum.GET_WORKING_TIME],
    },
    production: {
      "/production/work-shifts": routes[RoutesEnum.GET_TECHCELL_CALENDAR],
      "/production/tasks":
        routes[RoutesEnum.GET_STARTED_TASKS] && routes[RoutesEnum.GET_TC_USERS],
    },
    users: {
      "/users": routes[RoutesEnum.GET_USERS],
      "/users/days-off": routes[RoutesEnum.GET_DAYS_OFF],
      "/users/work-schedules": routes[RoutesEnum.GET_WORK_SHEDULES],
      "/users/calendars": routes[RoutesEnum.GET_CALENDARS],
    },
  };

  return menu;
};

export const IsSubMenu = (item: string) => {
  const routes = useSelector((state) => state.routes?.routes);
  const menu = MainMenuArr(routes);

  return Object.values(menu[item]).includes(true) ?? false;
};

export const getMenuItem = (routes: Record<string, boolean>) => {
  const menu = MainMenuArr(routes);
  const subMenu = Object.keys(menu).find((subMenu) =>
    Object.values(menu[subMenu]).includes(true)
  );
  const items = menu[subMenu as string];
  const menuItem = Object.keys(items)
    .map((item) => ({ name: item, value: items[item] }))
    .find((i) => i.value)?.name;

  return { subMenu, menuItem };
};
