import { request } from "lib/api";
import { handleErrors } from "lib/handleErrors";
import { TechcellGetResponse } from "types";

// Возвращает все возможные роли в формате {"<i​d>": "<Название>"}
const getRoles = async () => {
  const resp = await request("/availible-roles", "GET");

  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

const getScopes = async () => {
  const resp = await request("/scopes", "GET");

  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

export const editRolesScopes = async (role: string) => {
  const resp = await request(`/roles-scopes`, "PATCH", role);

  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

// Технологические ячейки с их складами и ячейками (опционально), доступные данному пользователю
const getTechCells = async (
  partner_code?: string
): Promise<Array<TechcellGetResponse>> => {
  const code = partner_code ? `&partner_code=${partner_code}` : "";
  const resp = await request(`/techcells?fmt=list${code}`, "GET");

  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

// Создаёт партнёра, возвращает его ID
const createPartner = async (body: string) => {
  const resp = await request("/partners", "POST", body);
  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

// Редактирует партнёра
const updatePartner = async (partner_id: number, body: string) => {
  const resp = await request(`/partners/${partner_id}`, "PATCH", body);
  if (!resp.ok) {
    handleErrors(resp);
  }
  return null;
};

// Удаляет партнёра
const deletePartner = async (partner_id: number) => {
  const resp = await request(`/partners/${partner_id}`, "DELETE");
  if (!resp.ok) {
    handleErrors(resp);
  }
  return null;
};

// Создаёт техячейку, возвращает его ID
const createTechCell = async (body: string) => {
  const resp = await request("/techcells", "POST", body);
  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

// Редактирует ТЯ
const updateTechCell = async (tc_id: number, body: string) => {
  const resp = await request(`/techcells/${tc_id}`, "PATCH", body);
  if (!resp.ok) {
    handleErrors(resp);
  }
  return null;
};

// Удаляет ТЯ
const deleteTechCell = async (tc_id: number) => {
  const resp = await request(`/techcells/${tc_id}`, "DELETE");
  if (!resp.ok) {
    handleErrors(resp);
  }
  return null;
};

// Запускает импорт данных из 1С для синхронизации
const synchronizeErp = async () => {
  const resp = await request("/synchronize-with-1c-data", "POST");
  if (!resp.ok) {
    handleErrors(resp);
  }
  return null;
};

// получает историю синхронизации
const getSyncHistory = async () => {
  const resp = await request("/get-sync-history?limit=10", "POST");
  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

const admin = {
  getRoles,
  getScopes,
  editRolesScopes,
  getTechCells,
  createPartner,
  updatePartner,
  deletePartner,
  createTechCell,
  updateTechCell,
  deleteTechCell,
  synchronizeErp,
  getSyncHistory,
};

export default admin;
