import { createSlice } from "@reduxjs/toolkit";
import { intl } from "lib/locale";

//actions
import { getCounterparty, deleteCounterparty } from "../actions";

//types
import { Counterparty } from "types";
import { IError } from "types/type-def";

interface IInitialState {
  counterpartyList: Array<Counterparty>;
  loading: boolean;
  error: IError;
}

const initialState: IInitialState = {
  counterpartyList: [],

  loading: false,
  error: null,
};

export const counterpartySlice = createSlice({
  name: "counterpartySlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Запрос списка клиентов для текущего партнера
    builder.addCase(getCounterparty.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getCounterparty.fulfilled, (state, action) => {
      state.loading = false;
      state.counterpartyList = action.payload.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
    });
    builder.addCase(getCounterparty.rejected, (state) => {
      state.loading = false;
      state.error = intl.formatMessage({
        id: "error.loading-error",
        defaultMessage: "An error occurred while loading",
      });
    });

    // удаление клиента
    builder.addCase(deleteCounterparty.fulfilled, (state, action) => {
      state.counterpartyList = action.payload;
    });
  },
});
