/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum ClientOrderFilter {
    ALL = 'all',
    APPROVED = 'approved',
}
