/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum OrderStatus {
    NEW = 'new',
    PLANNED = 'planned',
    IN_PRODUCTION = 'in_production',
    COMPLETED = 'completed',
}
