import { createAsyncThunk } from "@reduxjs/toolkit";
import { intl } from "lib/locale";

// types
import { IUserSalaryResp, ThunkAPI } from "types/type-def";
import { ProducedProductForReport, UserWorkingTime } from "types";

export const getDaysReport = createAsyncThunk<null, undefined, ThunkAPI>(
  "reports/getDaysReport",
  async (_, { extra: { api }, rejectWithValue }) => {
    try {
      const data = await api.reports.getDaysReport();

      var xlsxURL = window.URL.createObjectURL(data);
      let tempLink = document.createElement("a");
      tempLink.href = xlsxURL;
      tempLink.setAttribute("download", "report.xlsx");
      tempLink.click();

      return null;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

// Получение отчета по рабочему времени XLSX
export const getWorkingTimeReport = createAsyncThunk<
  null,
  {
    date_from: string;
    date_to: string;
    users: number[];
  },
  ThunkAPI
>(
  "reports/getWorkingTimeReport",
  async (
    { date_from, date_to, users },
    { extra: { api }, rejectWithValue }
  ) => {
    try {
      const data = await api.reports.getWorkingTimeReport({
        date_from,
        date_to,
        users,
      });

      var xlsxURL = window.URL.createObjectURL(data);
      let tempLink = document.createElement("a");
      tempLink.href = xlsxURL;
      tempLink.setAttribute("download", "workingTimeReport.xlsx");
      tempLink.click();

      return null;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

// Получение отчета по рабочему времени.
export const getUsersWorkingTime = createAsyncThunk<
  Array<UserWorkingTime>,
  {
    date_from: string;
    date_to: string;
    users: number[];
  },
  ThunkAPI
>(
  "reports/getUsersWorkingTime",
  async (
    { date_from, date_to, users },
    { extra: { api }, rejectWithValue }
  ) => {
    try {
      const data = await api.reports.getUsersWorkingTime({
        date_from,
        date_to,
        users,
      });

      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

// Получение отчета по ЗП всех пользователей Т.Я.
export const getSalaryReport = createAsyncThunk<
  Array<IUserSalaryResp>,
  {
    from_day: string;
    to_day: string;
    user_ids: string;
  },
  ThunkAPI
>(
  "reports/getSalaryReport",
  async (
    { from_day, to_day, user_ids },
    { extra: { api }, rejectWithValue, getState }
  ) => {
    const tc_id = getState().orders.currentTechCell;
    try {
      const data = await api.reports.getSalaryReport({
        tc_id,
        from_day,
        to_day,
        user_ids,
      });

      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

// Получение отчета по ЗП всех пользователей Т.Я. в xlsx
export const getSalaryReportXlsx = createAsyncThunk<
  null,
  {
    from_day: string;
    to_day: string;
    user_ids: string;
  },
  ThunkAPI
>(
  "reports/getSalaryReportXlsx",
  async (
    { from_day, to_day, user_ids },
    { extra: { api }, rejectWithValue, getState }
  ) => {
    const tc_id = getState().orders.currentTechCell;
    try {
      const data = await api.reports.getSalaryReportXlsx({
        tc_id,
        from_day,
        to_day,
        user_ids,
      });

      var xlsxURL = window.URL.createObjectURL(data);
      let tempLink = document.createElement("a");
      tempLink.href = xlsxURL;
      tempLink.setAttribute("download", "salaryReport.xlsx");
      tempLink.click();

      return null;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

export const getShipmentReportXlsx = createAsyncThunk<null, number, ThunkAPI>(
  "reports/getShipmentReportXlsx",
  async (shipment_id, { extra: { api }, rejectWithValue }) => {
    try {
      const data = await api.reports.getShipmentReportXlsx(shipment_id);

      var xlsxURL = window.URL.createObjectURL(data);
      let tempLink = document.createElement("a");
      tempLink.href = xlsxURL;
      tempLink.setAttribute(
        "download",
        `${intl.formatMessage({
          id: "storage.title-shipment",
          defaultMessage: "Shipment",
        })} №${shipment_id}.xlsx`
      );
      tempLink.click();

      return null;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

// Отчёт по выпущенной продукции
export const getProducedProductsReport = createAsyncThunk<
  Array<ProducedProductForReport>,
  {
    from_day: string;
    to_day: string;
    split_by_day: boolean;
  },
  ThunkAPI
>(
  "reports/getProducedProductsReport",
  async (
    { from_day, to_day, split_by_day },
    { extra: { api }, rejectWithValue, getState }
  ) => {
    const tc_id = getState().orders.currentTechCell;
    try {
      const data = await api.reports.getProducedProductsReport({
        tc_id,
        from_day,
        to_day,
        split_by_day,
      });

      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);
