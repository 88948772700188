/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum TableFormat {
    JSON = 'json',
    CSV = 'csv',
    XLSX = 'xlsx',
}
