import { combineReducers, configureStore, AnyAction } from "@reduxjs/toolkit";

import api from "lib/api";

// middlewares
import { errorsMiddleware } from "lib/middlewares";

// reducers
import { authSlice } from "feat/auth/authSlice";
import { localeSlice, paginationSlice } from "common/reducers";
import {
  rolesSlice,
  avaliableTechCellsSlice,
  partnersSlice,
  syncHistorySlice,
} from "feat/admin/reducers";

import {
  routesSlice,
  usersSlice,
  userSlice,
  currentUserSlice,
  daysOffSlice,
  calendarsSlice,
  workingSchedulesSlice,
} from "feat/user/reducers";
import {
  ordersSlice,
  currentOrderSlice,
  materialsSlice,
  nomenclatureSlice,
  ordersQuantitySlice,
} from "feat/orders/reducers";
import { productionSlice } from "feat/production/reducers";
// import { PrintingSlice } from "feat/storage/old_reducers";
import { counterpartySlice, specsSlice } from "feat/settings/reducers";
import { reportsSlice } from "feat/reports/reportsSlice";

const rootReducer = combineReducers({
  locale: localeSlice.reducer,
  pagination: paginationSlice.reducer,
  auth: authSlice.reducer,
  routes: routesSlice.reducer,
  currentUser: currentUserSlice.reducer,
  partners: partnersSlice.reducer,
  counterparty: counterpartySlice.reducer,
  users: usersSlice.reducer,
  user: userSlice.reducer,
  availableRoles: rolesSlice.reducer,
  workingSchedules: workingSchedulesSlice.reducer,
  calendars: calendarsSlice.reducer,
  daysOff: daysOffSlice.reducer,
  avaliableTechCells: avaliableTechCellsSlice.reducer,
  orders: ordersSlice.reducer,
  currentOrder: currentOrderSlice.reducer,
  materials: materialsSlice.reducer,
  production: productionSlice.reducer,
  reports: reportsSlice.reducer,
  nomenclature: nomenclatureSlice.reducer,
  syncHistory: syncHistorySlice.reducer,
  ordersQuantity: ordersQuantitySlice.reducer,
  specs: specsSlice.reducer,
  //printLabels: PrintingSlice.reducer,
});

const resettableRootReducer = (state: any, action: AnyAction) => {
  if (action.type === "auth/logout/fulfilled") {
    return rootReducer(undefined, action);
  }

  return rootReducer(state, action);
};

const store = configureStore({
  reducer: resettableRootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: {
        extraArgument: { api },
      },
    }).concat(errorsMiddleware),
});

export default store;

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof rootReducer>;
