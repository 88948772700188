/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum PaymentStatus {
    PAID = 'paid',
    NOT_PAID = 'not_paid',
    PARTIALLY_PAID = 'partially_paid',
    NOT_REQUIRE_PAYMENT = 'not_require_payment',
}
