import { createSlice } from "@reduxjs/toolkit";
import { intl } from "lib/locale";

// actions
import {
  getProdComponentsList,
  getOrderComponentsList,
  getClientOrderComponentsList,
  getProdOrderComponentsList,
  cleanMaterials,
} from "../actions";

// types
import { ComponentInDb } from "types";
import { IError } from "types/type-def";

interface IInitialState {
  materials: Array<ComponentInDb>;
  loading: boolean;
  error: IError;
}

const initialState: IInitialState = {
  materials: [],
  loading: false,
  error: null,
};

export const materialsSlice = createSlice({
  name: "materials",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Чистим список компонентов в сторе
    builder.addCase(cleanMaterials, (state) => {
      state.materials = [];
    });

    // Возвращает список компонентов для заказа на производство
    builder.addCase(getProdComponentsList.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getProdComponentsList.fulfilled, (state, action) => {
      state.loading = false;
      state.materials = action.payload.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
    });
    builder.addCase(getProdComponentsList.rejected, (state) => {
      state.loading = false;
      state.error = intl.formatMessage({
        id: "error.loading-error",
        defaultMessage: "An error occurred while loading",
      });
    });

    // Возвращает список компонентов для заказа покупателя
    builder.addCase(getOrderComponentsList.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getOrderComponentsList.fulfilled, (state, action) => {
      state.loading = false;
      state.materials = action.payload.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
    });
    builder.addCase(getOrderComponentsList.rejected, (state) => {
      state.loading = false;
      state.error = intl.formatMessage({
        id: "error.loading-error",
        defaultMessage: "An error occurred while loading",
      });
    });

    // Возвращает список компонентов для списка заказов покупателя
    builder.addCase(getClientOrderComponentsList.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getClientOrderComponentsList.fulfilled, (state, action) => {
      state.loading = false;
      state.materials = action.payload.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
    });
    builder.addCase(getClientOrderComponentsList.rejected, (state) => {
      state.loading = false;
      state.error = intl.formatMessage({
        id: "error.loading-error",
        defaultMessage: "An error occurred while loading",
      });
    });

    // Возвращает список компонентов для списка заказов на производство
    builder.addCase(getProdOrderComponentsList.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getProdOrderComponentsList.fulfilled, (state, action) => {
      state.loading = false;
      state.materials = action.payload.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
    });
    builder.addCase(getProdOrderComponentsList.rejected, (state) => {
      state.loading = false;
      state.error = intl.formatMessage({
        id: "error.loading-error",
        defaultMessage: "An error occurred while loading",
      });
    });
  },
});
