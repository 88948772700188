export const getPagination = () => {
  const defaultPageSize = 10;

  const pageSize = localStorage.getItem("pageSize");

  if (!pageSize) {
    localStorage.setItem("pageSize", defaultPageSize.toString());
    return defaultPageSize;
  }

  return parseInt(pageSize, 10);
};
