import { createAction, createAsyncThunk } from "@reduxjs/toolkit";

// utils
import { intl } from "lib/locale";
import { message } from "antd";

// types
import { ThunkAPI } from "types/type-def";
import {
  Counterparty,
  Spec_deprecated,
  NewCounterparty,
  NomenclatureLocalName,
} from "types";

// Запрос списка клиентов для текущего партнера
export const getCounterparty = createAsyncThunk<
  Counterparty[],
  undefined,
  ThunkAPI
>(
  "counterparty/getCounterparty",
  async (_, { extra: { api }, rejectWithValue }) => {
    try {
      const data = await api.settings.getCounterparty();

      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

// создание нового клиента
export const createCounterparty = createAsyncThunk<
  number | undefined,
  NewCounterparty,
  ThunkAPI
>(
  "counterparty/createCounterparty",
  async (body, { extra: { api }, rejectWithValue, dispatch, getState }) => {
    try {
      await api.settings.createCounterparty(JSON.stringify(body));

      message.success(
        intl.formatMessage({
          id: "success.created-success",
          defaultMessage: "Created success",
        })
      );

      await dispatch(getCounterparty());

      const counterpartyList = getState().counterparty.counterpartyList;
      const counterparty_id = counterpartyList.find(
        (item) => item.name === body.name
      )?.id;

      return counterparty_id;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

// создание нового клиента
export const updateCounterparty = createAsyncThunk<
  null,
  {
    id: string;
    body: NewCounterparty;
  },
  ThunkAPI
>(
  "counterparty/updateCounterparty",
  async ({ id, body }, { extra: { api }, rejectWithValue, dispatch }) => {
    try {
      await api.settings.updateCounterparty(id, JSON.stringify(body));
      message.success(
        intl.formatMessage({
          id: "success.updated-success",
          defaultMessage: "Updated success",
        })
      );

      return null;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

// удаление клиента
export const deleteCounterparty = createAsyncThunk<
  Array<Counterparty>,
  number,
  ThunkAPI
>(
  "counterparty/deleteCounterparty",
  async (id, { extra: { api }, rejectWithValue, getState }) => {
    const counterpartyList = getState().counterparty.counterpartyList;
    const data = counterpartyList.filter((client) => client.id !== id);
    try {
      await api.settings.deleteCounterparty(id);

      message.success(
        intl.formatMessage({
          id: "success.deleted-success",
          defaultMessage: "Deleted success",
        })
      );

      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

// Меняет названия у выбраных номенклатур
export const setNomenclatureLocalName = createAsyncThunk<
  null,
  Array<NomenclatureLocalName>,
  ThunkAPI
>(
  "nomenclature/setNomenclatureLocalName",
  async (changeInfo, { extra: { api }, rejectWithValue }) => {
    const body = JSON.stringify(changeInfo);
    try {
      await api.settings.setNomenclatureLocalName(body);

      message.success(
        intl.formatMessage({
          id: "success.updated-success",
          defaultMessage: "Updated success",
        })
      );

      return null;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

// Получить спецификацию
export const getSpec = createAsyncThunk<Spec_deprecated, number, ThunkAPI>(
  "nomenclature/getSpec",
  async (product_id, { extra: { api }, rejectWithValue }) => {
    try {
      const data = await api.settings.getSpec(product_id);

      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

// Отредактировать спецификацию
export const patchSpec = createAsyncThunk<
  null,
  {
    product_id: number;
    modifier: Array<{ operation_id: number; price?: number }>;
  },
  ThunkAPI
>(
  "nomenclature/patchSpec",
  async ({ product_id, modifier }, { extra: { api }, rejectWithValue }) => {
    const body = JSON.stringify({ operations: modifier });
    try {
      const data = await api.settings.patchSpec(product_id, body);

      message.success(
        intl.formatMessage({
          id: "success.updated-success",
          defaultMessage: "Updated success",
        })
      );

      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

// Скопировать спецификацию
export const copySpec = createAsyncThunk<
  null,
  {
    product_id: number;
    modifier: Array<number>;
  },
  ThunkAPI
>(
  "nomenclature/copySpec",
  async ({ product_id, modifier }, { extra: { api }, rejectWithValue }) => {
    const body = JSON.stringify(modifier);
    try {
      const data = await api.settings.copySpec(product_id, body);

      if (data) {
        return rejectWithValue(data.detail);
      }

      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

// Чистим спецификацию в сторе
export const cleanCurrentSpec = createAction<null>(
  "specifications/cleanCurrentSpec"
);
