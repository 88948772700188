import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { createIntlCtx, getCurrentLocale } from "../lib/locale";
import { getPagination } from "utils/getPagination";
import { ILocale } from "lib/enums";

export const localeSlice = createSlice({
  name: "locale",
  initialState: getCurrentLocale(),
  reducers: {
    setLocale: {
      reducer: (_, action: PayloadAction<ILocale>) => {
        return action.payload;
      },
      prepare: (locale) => {
        createIntlCtx(locale);
        localStorage.setItem("locale", locale);

        return { payload: locale };
      },
    },
  },
});

export const paginationSlice = createSlice({
  name: "pagination",
  initialState: getPagination(),
  reducers: {
    setPagination: {
      reducer: (_, action: PayloadAction<number>) => {
        return action.payload;
      },
      prepare: (pagination) => {
        localStorage.setItem("pageSize", pagination);
        return { payload: pagination };
      },
    },
  },
});

const { setLocale } = localeSlice.actions;

export { setLocale };

const { setPagination } = paginationSlice.actions;

export { setPagination };
