import loadable from "@loadable/component";

//components
import { PageNotFound } from "common/components/Errors";
import { LoginForm } from "feat/auth/components/LoginForm";

const UserProfilePage = loadable(
  () => import("feat/user/components/UserProfile/UserProfilePage")
);
const UsersListPage = loadable(
  () => import("feat/user/components/UserListTable/UserListPage")
);
const CreateUserPage = loadable(
  () => import("feat/user/components/UserCreate/CreateUserPage")
);
const RolesListPage = loadable(
  () => import("feat/admin/components/RolesList/RolesListPage")
);
const CustomerOrdersPage = loadable(
  () => import("feat/orders/components/CustomerOrders/CustomerOrdersPage")
);
const ProductionOrdersPage = loadable(
  () => import("feat/orders/components/ProductionOrders/ProductionOrdersPage")
);
const CustomerOrderInfoPage = loadable(
  () => import("feat/orders/components/CustomerOrderInfo/CustomerOrderInfoPage")
);
const ProductionOrderInfoPage = loadable(
  () =>
    import("feat/orders/components/ProductionOrderInfo/ProductionOrderInfoPage")
);
const ProductionSchedulePage = loadable(
  () =>
    import(
      "feat/orders/components/ProductionSchedulePage/ProductionSchedulePage"
    )
);
const TasksListPage = loadable(
  () => import("feat/production/components/TasksList/TasksListPage")
);
const UserTasksInfoPage = loadable(
  () => import("feat/production/components/UserTasksInfo/UserTasksInfoPage")
);

const WorkShiftsPage = loadable(
  () => import("feat/production/components/WorkShifts/WorkShiftsPage")
);
const CalendarsPage = loadable(
  () => import("feat/user/components/Calendars/CalendarsPage")
);
const CreateCalendarPage = loadable(
  () => import("feat/user/components/CreateCalendar/CreateCalendarPage")
);
const CalendarPreviewPage = loadable(
  () => import("feat/user/components/CalendarPreview/CalendarPreviewPage")
);
const ImportCalendarPage = loadable(
  () => import("feat/user/components/ImportCalendar/ImportCalendarPage")
);
const WorkSchedulesPage = loadable(
  () => import("feat/user/components/WorkSchedules/WorkSchedulesPage")
);
const UpdateCalendarPage = loadable(
  () => import("feat/user/components/UpdateCalendar/UpdateCalendarPage")
);
const CreateWorkSchedulesPage = loadable(
  () => import("feat/user/components/WorkSchedulesForm/CreateWorkSchedulesPage")
);
const EditWorkSchedulesPage = loadable(
  () => import("feat/user/components/WorkSchedulesForm/EditWorkSchedulesPage")
);
const DaysOffPage = loadable(
  () => import("feat/user/components/DaysOff/DaysOffPage")
);
const CreateDaysOffPage = loadable(
  () => import("feat/user/components/CreateDaysOff/CreateDaysOffPage")
);
const EditDaysOffPage = loadable(
  () => import("feat/user/components/DaysOff/EditDaysOff/EditDaysOffPage")
);
const WorkingTimePage = loadable(
  () => import("feat/reports/components/WorkingTime/WorkingTimePage")
);
const ProductionSalaryPage = loadable(
  () => import("feat/reports/components/ProductionSalary/ProductionSalaryPage")
);

const PrintingLabelsPage = loadable(
  () => import("feat/storage/components/PrintingLabels/PrintingLabelsPage")
);

const FulfillmentPage = loadable(
  () => import("feat/orders/components/FulfillmentOfOrders/FulfillmentPage")
);
const PartnersPage = loadable(
  () => import("feat/admin/components/PartnersList/PartnersPage")
);
const PartnerPage = loadable(
  () => import("feat/admin/components/PartnerPage/PartnerPage")
);
const CreateCustomerOrderPage = loadable(
  () =>
    import("feat/orders/components/CreateCustomerOrder/CreateCustomerOrderPage")
);
const TechCellsListPage = loadable(
  () => import("feat/admin/components/TechCellsList/TechCellsListPage")
);
const TechCellPage = loadable(
  () => import("feat/admin/components/TechCellPage/TechCellPage")
);
const ClientsPage = loadable(
  () => import("feat/settings/components/ClientsList/ClientsPage")
);
const ClientPage = loadable(
  () => import("feat/settings/components/ClientPage/ClientPage")
);
const MaterialsReportPage = loadable(
  () => import("feat/reports/components/MaterialsReport/MaterialsReportPage")
);
const ProducedProductsReportPage = loadable(
  () =>
    import(
      "feat/reports/components/ProducedProductsReport/ProducedProductsReportPage"
    )
);
const SynchronizePage = loadable(
  () => import("feat/admin/components/SynchronizePage/SynchronizePage")
);
const ProductsManagementPage = loadable(
  () =>
    import("feat/settings/components/ProductsManagement/ProductsManagementPage")
);

export {
  UserProfilePage,
  UsersListPage,
  CreateUserPage,
  RolesListPage,
  CustomerOrdersPage,
  ProductionOrdersPage,
  CustomerOrderInfoPage,
  ProductionOrderInfoPage,
  ProductionSchedulePage,
  TasksListPage,
  UserTasksInfoPage,
  WorkShiftsPage,
  CalendarsPage,
  CreateCalendarPage,
  CalendarPreviewPage,
  ImportCalendarPage,
  WorkSchedulesPage,
  UpdateCalendarPage,
  CreateWorkSchedulesPage,
  EditWorkSchedulesPage,
  DaysOffPage,
  CreateDaysOffPage,
  EditDaysOffPage,
  WorkingTimePage,
  ProductionSalaryPage,
  PrintingLabelsPage,
  FulfillmentPage,
  PartnersPage,
  LoginForm,
  PageNotFound,
  PartnerPage,
  CreateCustomerOrderPage,
  TechCellsListPage,
  TechCellPage,
  ClientsPage,
  ClientPage,
  MaterialsReportPage,
  ProducedProductsReportPage,
  SynchronizePage,
  ProductsManagementPage,
};
