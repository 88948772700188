import { createSlice, current } from "@reduxjs/toolkit";
import { intl } from "lib/locale";

// actions
import {
  getCustomerOrders,
  getProductionOrders,
  deleteTask,
  unstaffTask,
  setOrderSupplyStatus,
  submitProduction,
  pauseProduction,
} from "../actions";

// types
import {
  ClientOrdersResponse,
  OrderStatus,
  ProductionOrderBase,
  FilterOrders,
  ProdOrderStatus,
} from "types";
import { IError } from "types/type-def";

interface IInitialState {
  customerOrders: ClientOrdersResponse[];
  customerOrdersQuantity: Record<string, number>;

  productionOrders: ProductionOrderBase[];
  productionOrdersQuantity: Record<string, number>;

  filter: OrderStatus | FilterOrders;
  productionOrderFilter: FilterOrders | "all";
  currentTechCell: number;

  loading: boolean;
  error: IError;
}

const initialState: IInitialState = {
  customerOrders: [],
  customerOrdersQuantity: {},

  productionOrders: [],
  productionOrdersQuantity: {},

  filter: OrderStatus.NEW,
  productionOrderFilter: "all",
  currentTechCell: 0,

  loading: false,
  error: null,
};

export const ordersSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
    setProductionOrderFilter: (state, action) => {
      state.productionOrderFilter = action.payload;
    },
    setProductionOrders: (state, action) => {
      state.productionOrders = action.payload;
    },
    setCustomerOrders: (state, action) => {
      const { quantity_by_status, data } = action.payload ?? {};
      state.customerOrders = data ?? [];
      state.customerOrdersQuantity = quantity_by_status ?? {};
    },
    setCurrentTechCell: (state, action) => {
      state.currentTechCell = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Загружаем список заказов покупателей
    builder.addCase(getCustomerOrders.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getCustomerOrders.fulfilled, (state, action) => {
      state.loading = false;
      state.customerOrders = action.payload.data;
      state.customerOrdersQuantity = action.payload.quantity;
    });
    builder.addCase(getCustomerOrders.rejected, (state) => {
      state.loading = false;
      state.error = intl.formatMessage({
        id: "error.loading-error",
        defaultMessage: "An error occurred while loading",
      });
    });

    // Загружаем список заказов на производство
    builder.addCase(getProductionOrders.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getProductionOrders.fulfilled, (state, action) => {
      state.loading = false;
      state.productionOrders = action.payload.data;
      state.productionOrdersQuantity = action.payload.quantity;
    });
    builder.addCase(getProductionOrders.rejected, (state) => {
      state.loading = false;
      state.error = intl.formatMessage({
        id: "error.loading-error",
        defaultMessage: "An error occurred while loading",
      });
    });

    //
    builder.addCase(deleteTask.fulfilled, (state, action) => {
      state.loading = false;
      state.productionOrders = current(state.productionOrders).filter(
        (order) => order.task_id !== action.payload
      );
    });

    //
    builder.addCase(unstaffTask.fulfilled, (state, action) => {
      state.loading = false;
      //@ts-expect-error
      state.productionOrders = current(state.productionOrders).map((order) =>
        order.task_id === action.payload
          ? { ...order, supply_status: "not_staffed" }
          : order
      );
    });

    //  Меняет статус комплектования материалами для ЗнП
    builder.addCase(setOrderSupplyStatus.fulfilled, (state, action) => {
      const { status, task_id } = action.payload;
      state.productionOrders = current(state.productionOrders).map((order) =>
        order.task_id === task_id ? { ...order, supply_status: status } : order
      );
    });

    // запускает заказ в производство
    builder.addCase(submitProduction.fulfilled, (state, action) => {
      const taskIds = action.payload;
      state.productionOrders = current(state.productionOrders).map((order) =>
        taskIds.includes(order.task_id)
          ? { ...order, prod_status: ProdOrderStatus.IN_PRODUCTION }
          : order
      );
    });

    // ставит заказ на паузу
    builder.addCase(pauseProduction.fulfilled, (state, action) => {
      const taskId = action.payload;
      state.productionOrders = current(state.productionOrders).map((order) =>
        order.task_id === taskId
          ? { ...order, prod_status: ProdOrderStatus.NOT_IN_PRODUCTION }
          : order
      );
    });
  },
});
