import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { intl } from "lib/locale";

// types
import { MenuProps } from "antd/lib/menu";

type MenuItem = Required<MenuProps>["items"][number];

export const MenuItems = () => {
  const items: MenuItem[] = [
    {
      label: intl.formatMessage({
        id: "sync.sync",
        defaultMessage: "Synchronization",
      }),
      key: "sync",
      type: "group",
      children: [
        {
          label: (
            <Link to="/admin/sync">
              <FormattedMessage
                id="app.main-menu-sync"
                defaultMessage="Synchronization"
              />
            </Link>
          ),
          key: "/admin/sync",
        },
      ],
    },
    {
      label: intl.formatMessage({
        id: "app.main-menu-administration",
        defaultMessage: "Administration",
      }),
      key: "admin",
      type: "group",
      children: [
        {
          label: (
            <Link to="/admin/partners">
              <FormattedMessage
                id="partner.partners"
                defaultMessage="Partners"
              />
            </Link>
          ),
          key: "/admin/partners",
        },
        {
          label: (
            <Link to="/admin/techcells">
              <FormattedMessage
                id="app.main-menu-tech-cells"
                defaultMessage="Tech cells"
              />
            </Link>
          ),
          key: "/admin/techcells",
        },
      ],
    },
    {
      label: intl.formatMessage({
        id: "app.admin-menu-users",
        defaultMessage: "Users",
      }),
      key: "sync",
      type: "group",
      children: [
        {
          label: (
            <Link to="/admin/users">
              <FormattedMessage
                id="app.main-menu-users"
                defaultMessage="Staff"
              />
            </Link>
          ),
          key: "/admin/users",
        },
        {
          label: (
            <Link to="/admin/roles">
              <FormattedMessage id="user.roles-title" defaultMessage="Roles" />
            </Link>
          ),
          key: "/admin/roles",
        },
      ],
    },
  ];

  return items;
};
