import { createAsyncThunk, createAction } from "@reduxjs/toolkit";

// utils
import { intl } from "lib/locale";
import { message } from "antd";

// types
import { ThunkAPI, Maybe, IRole } from "types/type-def";
import {
  Scope,
  TechcellGetResponse,
  PartnerCreateInfo,
  PartnerDB,
  TechcellCreateInfo,
  SystemActions,
} from "types";

// выбор партнера
export const togglePartner = createAction<Maybe<PartnerDB>>(
  "partners/togglePartner"
);

// Возвращает все возможные роли в формате {"<i​d>": "<Название>"}
export const getRoles = createAsyncThunk<Array<IRole>, undefined, ThunkAPI>(
  "users/getRoles",
  async (_, { extra: { api }, rejectWithValue }) => {
    try {
      const data = await api.admin.getRoles();

      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

export const getScopes = createAsyncThunk<Array<Scope>, undefined, ThunkAPI>(
  "users/getScopes",
  async (_, { extra: { api }, rejectWithValue }) => {
    try {
      const data = await api.admin.getScopes();

      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

export const editRolesScopes = createAsyncThunk<null, undefined, ThunkAPI>(
  "users/editRolesScopes",
  async (role, { extra: { api }, rejectWithValue }) => {
    try {
      await api.admin.editRolesScopes(JSON.stringify(role));

      message.success(
        intl.formatMessage({
          id: "success.updated-success",
          defaultMessage: "Updated success",
        })
      );

      return null;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

// Технологические ячейки с их складами и ячейками (опционально), доступные данному пользователю
export const getTechCells = createAsyncThunk<
  TechcellGetResponse[],
  string | undefined,
  ThunkAPI
>(
  "avaliableTechCells/getTechCells",
  async (partner_code, { extra: { api }, rejectWithValue }) => {
    try {
      const data = await api.admin.getTechCells(partner_code);

      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

// Создаёт партнёра, возвращает его ID
export const createPartner = createAsyncThunk<
  PartnerDB[],
  PartnerCreateInfo,
  ThunkAPI
>(
  "partners/createPartner",
  async (body, { extra: { api }, rejectWithValue, getState }) => {
    const partners = getState().partners.partners;
    try {
      const newPartnerId = await api.admin.createPartner(JSON.stringify(body));
      const data = [...partners, { ...body, id: newPartnerId }].sort((a, b) =>
        a.name.localeCompare(b.name)
      );

      message.success(
        intl.formatMessage({
          id: "success.created-success",
          defaultMessage: "Created success",
        })
      );

      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

// Редактирует партнёра
export const updatePartner = createAsyncThunk<PartnerDB[], PartnerDB, ThunkAPI>(
  "partners/updatePartner",
  async (partner, { extra: { api }, rejectWithValue, getState }) => {
    const partners = getState().partners.partners;
    const partner_id = partner.id;
    const body = JSON.stringify(partner);
    const data = partners.map((item) =>
      item.id === partner_id ? partner : item
    );
    try {
      await api.admin.updatePartner(partner_id, body);

      message.success(
        intl.formatMessage({
          id: "success.updated-success",
          defaultMessage: "Updated success",
        })
      );

      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

// Удаляет партнёра
export const deletePartner = createAsyncThunk<PartnerDB[], number, ThunkAPI>(
  "partners/deletePartner",
  async (partner_id, { extra: { api }, rejectWithValue, getState }) => {
    const partners = getState().partners.partners;
    const data = partners.filter(({ id }) => id !== partner_id);
    try {
      await api.admin.deletePartner(partner_id);

      message.success(
        intl.formatMessage({
          id: "success.deleted-success",
          defaultMessage: "Deleted success",
        })
      );

      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

// Создаёт техячейку, возвращает его ID
export const createTechCell = createAsyncThunk<
  null,
  TechcellCreateInfo,
  ThunkAPI
>(
  "avaliableTechCells/createTechCell",
  async (body, { extra: { api }, rejectWithValue, dispatch }) => {
    try {
      await api.admin.createTechCell(JSON.stringify(body));

      await dispatch(getTechCells());

      message.success(
        intl.formatMessage({
          id: "success.created-success",
          defaultMessage: "Created success",
        })
      );

      return null;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

// Редактирует ТЯ
export const updateTechCell = createAsyncThunk<
  null,
  TechcellCreateInfo & { id: number },
  ThunkAPI
>(
  "avaliableTechCells/updateTechCell",
  async (techCell, { extra: { api }, rejectWithValue, dispatch }) => {
    const tc_id = techCell.id;
    const body = JSON.stringify(techCell);
    try {
      await api.admin.updateTechCell(tc_id, body);
      await dispatch(getTechCells());

      message.success(
        intl.formatMessage({
          id: "success.updated-success",
          defaultMessage: "Updated success",
        })
      );

      return null;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

// Удаляет ТЯ
export const deleteTechCell = createAsyncThunk<
  TechcellGetResponse[],
  number,
  ThunkAPI
>(
  "avaliableTechCells/deleteTechCell",
  async (tc_id, { extra: { api }, rejectWithValue, getState }) => {
    const techCells = getState().avaliableTechCells.techCells;
    const data = techCells.filter(({ id }) => id !== tc_id);
    try {
      await api.admin.deleteTechCell(tc_id);

      message.success(
        intl.formatMessage({
          id: "success.deleted-success",
          defaultMessage: "Deleted success",
        })
      );

      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

// Запускает импорт данных из 1С для синхронизации
export const synchronizeErp = createAsyncThunk<null, undefined, ThunkAPI>(
  "admin/synchronizeErp",
  async (_, { extra: { api }, rejectWithValue }) => {
    try {
      await api.admin.synchronizeErp();

      message.success(
        intl.formatMessage({
          id: "success.updated-success",
          defaultMessage: "Updated success",
        })
      );

      return null;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

// получает историю синхронизации
export const getSyncHistory = createAsyncThunk<
  SystemActions[],
  undefined,
  ThunkAPI
>("admin/getSyncHistory", async (_, { extra: { api }, rejectWithValue }) => {
  try {
    const data = await api.admin.getSyncHistory();

    return data;
  } catch (error) {
    console.error(error);
    return rejectWithValue(error);
  }
});
