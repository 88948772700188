/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Статус комплектования ЗнП материалами
 * Значения должны совпадать с enum-ом `order_supply_status` в БД ERP
 */
export enum ProdOrderSupplyStatus {
    NOT_STAFFED = 'not_staffed',
    IN_PROGRESS = 'in_progress',
    STAFFED = 'staffed',
}
