import { createSlice } from "@reduxjs/toolkit";
import { intl } from "lib/locale";

//actions
import { getSpec, cleanCurrentSpec, copySpec } from "../actions";

//types
import { Spec_deprecated } from "types";
import { IError, Maybe } from "types/type-def";

interface IInitialState {
  currentSpec: Maybe<Spec_deprecated>;
  loading: boolean;
  error: IError | unknown;
}

const initialState: IInitialState = {
  currentSpec: null,
  loading: false,
  error: null,
};

export const specsSlice = createSlice({
  name: "specifications",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Получить спецификацию
    builder.addCase(getSpec.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getSpec.fulfilled, (state, action) => {
      state.loading = false;
      state.currentSpec = action.payload;
    });
    builder.addCase(getSpec.rejected, (state) => {
      state.loading = false;
      state.error = intl.formatMessage({
        id: "error.loading-error",
        defaultMessage: "An error occurred while loading",
      });
    });

    // Чистим стор
    builder.addCase(cleanCurrentSpec, (state) => {
      state.currentSpec = null;
    });

    // Копирование спецификации
    builder.addCase(copySpec.pending, (state) => {
      state.error = null;
    });
    builder.addCase(copySpec.rejected, (state, action) => {
      state.error = action.payload;
    });
  },
});
