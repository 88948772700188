import { createSlice } from "@reduxjs/toolkit";

// actions
import {
  getUsersWorkingTime,
  getSalaryReport,
  getProducedProductsReport,
} from "./actions";

// utils
import { intl } from "lib/locale";

// types
import { UserWorkingTime, ProducedProductForReport } from "types";
import { IError, IUserSalaryResp } from "types/type-def";

interface IInitialState {
  usersWorkingTime: UserWorkingTime[];
  usersSalary: IUserSalaryResp[];
  producedProducts: ProducedProductForReport[];

  loading: boolean;
  error: IError;
}

const initialState: IInitialState = {
  usersWorkingTime: [],
  usersSalary: [],
  producedProducts: [],

  loading: false,
  error: null,
};

export const reportsSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Получение отчета по рабочему времени.
    builder.addCase(getUsersWorkingTime.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getUsersWorkingTime.fulfilled, (state, action) => {
      state.loading = false;
      state.usersWorkingTime = action.payload;
    });
    builder.addCase(getUsersWorkingTime.rejected, (state) => {
      state.loading = false;
      state.error = intl.formatMessage({
        id: "error.loading-error",
        defaultMessage: "An error occurred while loading",
      });
    });

    // Получение отчета по ЗП всех пользователей Т.Я.
    builder.addCase(getSalaryReport.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getSalaryReport.fulfilled, (state, action) => {
      state.loading = false;
      state.usersSalary = action.payload;
    });
    builder.addCase(getSalaryReport.rejected, (state) => {
      state.loading = false;
      state.error = intl.formatMessage({
        id: "error.loading-error",
        defaultMessage: "An error occurred while loading",
      });
    });

    // Отчёт по выпущенной продукции
    builder.addCase(getProducedProductsReport.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getProducedProductsReport.fulfilled, (state, action) => {
      state.loading = false;
      state.producedProducts = action.payload;
    });
    builder.addCase(getProducedProductsReport.rejected, (state) => {
      state.loading = false;
      state.error = intl.formatMessage({
        id: "error.loading-error",
        defaultMessage: "An error occurred while loading",
      });
    });
  },
});
