import { useState } from "react";

// components
import { Drawer } from "antd";
import { CloseOutlined, MenuOutlined } from "@ant-design/icons";
import { PartnerSelect } from "common/components/PartnerSelect";

// styles
import styles from "./MobileMainMenu.module.css";

interface IProps {
  isPartnerChecked: boolean;
  getMenu: (isMobile?: boolean) => JSX.Element | undefined;
  isAdmin?: boolean;
}

export const MobileMainMenu: React.FC<IProps> = ({
  isAdmin,
  isPartnerChecked,
  getMenu,
}) => {
  const [visible, setVisible] = useState(false);

  const toggleCollapsed = () => {
    setVisible((prev) => !prev);
  };

  const onClose = () => {
    setVisible(false);
  };

  if (!isPartnerChecked && !isAdmin) {
    return null;
  }

  return (
    <Drawer
      title={<PartnerSelect isAdminPages={isAdmin} />}
      placement="left"
      handler={
        <div
          className={styles.collapsedButton}
          onClick={() => toggleCollapsed()}
        >
          {visible ? <CloseOutlined /> : <MenuOutlined />}
        </div>
      }
      onClose={onClose}
      visible={visible}
      bodyStyle={{
        padding: 8,
      }}
      headerStyle={{ paddingLeft: 8 }}
      className={styles.drawer}
    >
      {getMenu(true)}
    </Drawer>
  );
};
