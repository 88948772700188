import { createSlice, current } from "@reduxjs/toolkit";
import { intl } from "lib/locale";

//actions
import { getDaysOff, deleteDayOff } from "../actions";

//types
import { IError } from "types/type-def";
import { DayOff } from "types";

interface IInitialState {
  daysOff: Array<DayOff>;
  loading: boolean;
  error: IError;
}

const initialState: IInitialState = {
  daysOff: [],
  loading: false,
  error: null,
};

export const daysOffSlice = createSlice({
  name: "daysOff",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // список отпуска и отгулы
    builder.addCase(getDaysOff.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getDaysOff.fulfilled, (state, action) => {
      state.loading = false;
      state.daysOff = action.payload;
    });
    builder.addCase(getDaysOff.rejected, (state) => {
      state.loading = false;
      state.error = intl.formatMessage({
        id: "error.loading-error",
        defaultMessage: "An error occurred while loading",
      });
    });

    // удаляем отпуск/отгул
    builder.addCase(deleteDayOff.fulfilled, (state, action) => {
      state.loading = false;
      state.daysOff = current(state.daysOff).filter(
        (dayOff) => dayOff.id !== action.payload
      );
    });
  },
});
