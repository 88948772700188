/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum ProdOrderStatus {
    IN_PRODUCTION = 'in_production',
    NOT_IN_PRODUCTION = 'not_in_production',
    ENDED = 'ended',
}
