// @ts-nocheck

class ApiError extends Error {
  constructor(resp) {
    super(resp);
    this.name = "ApiError";
    this.code = resp.status;
    this.message = resp;
  }
}

export const handleErrors = (resp: Response) => {
  throw new ApiError(resp);
};
