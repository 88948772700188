import { Switch, Route } from "react-router-dom";
import { PrivateRoute } from "common/components/PrivateRoute";

import {
  UserProfilePage,
  UsersListPage,
  CreateUserPage,
  RolesListPage,
  CustomerOrdersPage,
  ProductionOrdersPage,
  CustomerOrderInfoPage,
  ProductionOrderInfoPage,
  ProductionSchedulePage,
  TasksListPage,
  UserTasksInfoPage,
  WorkShiftsPage,
  CalendarsPage,
  CreateCalendarPage,
  CalendarPreviewPage,
  ImportCalendarPage,
  WorkSchedulesPage,
  UpdateCalendarPage,
  CreateWorkSchedulesPage,
  EditWorkSchedulesPage,
  DaysOffPage,
  CreateDaysOffPage,
  EditDaysOffPage,
  WorkingTimePage,
  ProductionSalaryPage,
  PrintingLabelsPage,
  FulfillmentPage,
  PartnersPage,
  LoginForm,
  PageNotFound,
  PartnerPage,
  CreateCustomerOrderPage,
  TechCellsListPage,
  TechCellPage,
  ClientsPage,
  ClientPage,
  MaterialsReportPage,
  ProducedProductsReportPage,
  SynchronizePage,
  ProductsManagementPage,
} from "./pages";

export const Routes: React.FC = () => {
  return (
    <Switch>
      <Route path="/login" component={LoginForm} />

      {/* admin */}
      <PrivateRoute path="/admin/partners" exact component={PartnersPage} />
      <PrivateRoute path="/admin/partners/:id" component={PartnerPage} />
      <PrivateRoute path="/admin/roles" component={RolesListPage} />
      <PrivateRoute path="/admin/techcells/:id" component={TechCellPage} />
      <PrivateRoute path="/admin/users" component={UsersListPage} />
      <PrivateRoute path="/admin/create-user" component={CreateUserPage} />
      <PrivateRoute path="/admin/profile/:id" component={UserProfilePage} />
      <PrivateRoute path="/admin/sync" component={SynchronizePage} />
      <PrivateRoute
        path="/admin/techcells"
        exact
        component={TechCellsListPage}
      />
      {/* admin */}

      {/* USERS */}
      <PrivateRoute exact path="/users" component={UsersListPage} />
      <PrivateRoute path="/users/profile/:id?" component={UserProfilePage} />
      <PrivateRoute path="/users/create-user" component={CreateUserPage} />
      <PrivateRoute exact path="/users/days-off" component={DaysOffPage} />
      <PrivateRoute
        path="/users/days-off/edit/:id?"
        component={EditDaysOffPage}
      />
      <PrivateRoute
        exact
        path="/users/days-off/create"
        component={CreateDaysOffPage}
      />
      {/* USERS */}

      {/* PRODUCTION */}
      <PrivateRoute exact path="/orders" component={ProductionOrdersPage} />
      <PrivateRoute
        exact
        path="/orders/production-schedule"
        component={ProductionSchedulePage}
      />
      <PrivateRoute
        exact
        path="/orders/customer-orders"
        component={CustomerOrdersPage}
      />
      <PrivateRoute path="/orders/create" component={CreateCustomerOrderPage} />
      <PrivateRoute
        exact
        path="/orders/customer-orders/:key"
        component={CustomerOrderInfoPage}
      />
      <PrivateRoute
        exact
        path="/orders/production-orders"
        component={ProductionOrdersPage}
      />
      <PrivateRoute
        exact
        path="/orders/production-orders/:task_id/:techCell"
        component={ProductionOrderInfoPage}
      />
      <PrivateRoute path="/orders/fulfillment" component={FulfillmentPage} />
      <PrivateRoute
        exact
        path="/production/work-shifts"
        component={WorkShiftsPage}
      />
      <PrivateRoute exact path="/production/tasks" component={TasksListPage} />
      <PrivateRoute
        path="/production/user/:user_id?"
        component={UserTasksInfoPage}
      />
      {/* PRODUCTION */}

      {/* TOOLS */}
      <PrivateRoute
        path="/storage/printing-labels"
        component={PrintingLabelsPage}
      />
      {/* TOOLS */}

      {/* REPORTS */}
      <PrivateRoute path="/reports/working-time" component={WorkingTimePage} />
      <PrivateRoute
        path="/reports/production-salary"
        component={ProductionSalaryPage}
      />
      <PrivateRoute path="/reports/materials" component={MaterialsReportPage} />
      <PrivateRoute
        path="/reports/produced-products"
        component={ProducedProductsReportPage}
      />
      {/* REPORTS */}

      {/* SETTINGS */}
      <PrivateRoute exact path="/settings/clients" component={ClientsPage} />
      <PrivateRoute path="/settings/clients/:id" component={ClientPage} />
      <PrivateRoute exact path="/users/calendars" component={CalendarsPage} />
      <PrivateRoute
        exact
        path="/users/calendars/:id?/preview"
        component={CalendarPreviewPage}
      />
      <PrivateRoute
        exact
        path="/users/calendars/:id/edit"
        component={UpdateCalendarPage}
      />
      <PrivateRoute
        path="/users/calendars/create"
        component={CreateCalendarPage}
      />
      <PrivateRoute
        path="/users/calendars/import/:id?"
        component={ImportCalendarPage}
      />
      <PrivateRoute
        exact
        path="/users/work-schedules"
        component={WorkSchedulesPage}
      />
      <PrivateRoute
        path="/users/work-schedules/create"
        component={CreateWorkSchedulesPage}
      />
      <PrivateRoute
        path="/users/work-schedules/:id?/:type?"
        component={EditWorkSchedulesPage}
      />
      <PrivateRoute path="/settings/costs" component={ProductsManagementPage} />
      {/* SETTINGS */}

      <Route path="/404" component={PageNotFound} />
      <PrivateRoute exact path="/" component={PartnersPage} />
      <Route component={PageNotFound} />
    </Switch>
  );
};
