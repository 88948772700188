import { createAction, createAsyncThunk } from "@reduxjs/toolkit";

// utils
import { intl } from "lib/locale";
import { history } from "utils/history";
import { message } from "antd";

// types
import { ThunkAPI, IResponce } from "types/type-def";
import {
  ClientOrdersResponse,
  OrderMoveInfo,
  OrderSplitInfo,
  ProductionOrderBase,
  StartOrderInfo,
  SubmitProductionInfo,
  NewClientOrder,
  ProdOrderSupplyStatus,
  ComponentInDb,
  NomHierarchy,
  LogInfo,
  QuantityInfo,
} from "types";

// Возвращает заказы покупателей, которые оплачены или согласованы, но не отгружены
export const getCustomerOrders = createAsyncThunk<
  IResponce<Array<ClientOrdersResponse>>,
  { page: number; term?: string },
  ThunkAPI
>(
  "orders/getCustomerOrders",
  async ({ page, term }, { extra: { api }, rejectWithValue, getState }) => {
    const pageSize = getState().pagination;
    const filter = getState().orders.filter;
    try {
      const data = await api.orders.getCustomerOrders({
        page,
        pageSize,
        filter,
        term,
      });

      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

// Создание заказа покупателя
export const createOrder = createAsyncThunk<
  ClientOrdersResponse,
  NewClientOrder,
  ThunkAPI
>(
  "orders/createOrder",
  async (newOrder, { extra: { api }, rejectWithValue }) => {
    try {
      const data = await api.orders.createOrder(JSON.stringify(newOrder));

      message.success(
        intl.formatMessage({
          id: "success.created-success",
          defaultMessage: "Created success",
        })
      );

      history.push(`/orders/customer-orders/${data.order_id}`);

      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

// Возвращает информацию о заказе
export const getOrder = createAsyncThunk<
  ClientOrdersResponse,
  { key: string },
  ThunkAPI
>("orders/getOrder", async ({ key }, { extra: { api }, rejectWithValue }) => {
  try {
    const data = await api.orders.getOrder({ key });

    return data;
  } catch (error) {
    console.error(error);
    return rejectWithValue(error);
  }
});

// Возвращает очередь ЗнП
export const getProductionOrders = createAsyncThunk<
  IResponce<Array<ProductionOrderBase>>,
  { page: number; term?: string },
  ThunkAPI
>(
  "orders/getProductionOrders",
  async ({ page, term }, { extra: { api }, rejectWithValue, getState }) => {
    const currentTechCell = getState().orders.currentTechCell;
    const filter = getState().orders.productionOrderFilter;
    const pageSize = getState().pagination;

    try {
      const data = await api.orders.getProductionOrders({
        page,
        pageSize,
        currentTechCell,
        filter,
        term,
      });

      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

// Возвращает задачу из очереди ЗнП
export const getProductionOrder = createAsyncThunk<
  ProductionOrderBase,
  { task_id: string; techCell: string },
  ThunkAPI
>(
  "orders/getProductionOrder",
  async ({ task_id, techCell }, { extra: { api }, rejectWithValue }) => {
    const tc_id = parseInt(techCell, 10);
    try {
      const data = await api.orders.getProductionOrder(task_id, tc_id);

      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

// Move Task
export const moveProductionOrder = createAsyncThunk<
  null,
  OrderMoveInfo[],
  ThunkAPI
>(
  "orders/moveProductionOrder",
  async (orders, { extra: { api }, rejectWithValue }) => {
    try {
      await api.orders.moveProductionOrder(JSON.stringify(orders));
      return null;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

// Возвращает историю действий заказа
export const getTaskHistory = createAsyncThunk<
  LogInfo[],
  { task_id: number; tc_id: number },
  ThunkAPI
>(
  "orders/getTaskHistory",
  async ({ task_id, tc_id }, { extra: { api }, rejectWithValue }) => {
    try {
      const data = await api.orders.getTaskHistory(task_id, tc_id);

      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

// Split task
export const splitTask = createAsyncThunk<undefined, OrderSplitInfo, ThunkAPI>(
  "orders/splitTask",
  async (new_data, { extra: { api }, rejectWithValue }) => {
    try {
      const data = await api.orders.splitTask(JSON.stringify(new_data));

      history.push("/orders/production-orders");

      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

export const deleteTask = createAsyncThunk<
  number,
  {
    task_id: number;
    tc_id: number;
  },
  ThunkAPI
>(
  "orders/deleteTask",
  async ({ task_id, tc_id }, { extra: { api }, rejectWithValue }) => {
    try {
      await api.orders.deleteTask(task_id, tc_id);

      message.success(
        intl.formatMessage({
          id: "success.updated-success",
          defaultMessage: "Updated success",
        })
      );

      return task_id;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

export const unstaffTask = createAsyncThunk<number, number, ThunkAPI>(
  "orders/unstaffTask",
  async (task_id, { extra: { api }, rejectWithValue }) => {
    try {
      await api.orders.unstaffTask(task_id);

      message.success(
        intl.formatMessage({
          id: "success.updated-success",
          defaultMessage: "Updated success",
        })
      );

      return task_id;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

// Получение истории операции над заказом покупателя
export const getClientOrderHistory = createAsyncThunk<
  Array<LogInfo>,
  string,
  ThunkAPI
>(
  "orders/getClientOrderHistory",
  async (key, { extra: { api }, rejectWithValue }) => {
    try {
      const data = await api.orders.getClientOrderHistory(key);

      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

export const findOrder = createAsyncThunk<any, string, ThunkAPI>(
  "orders/findOrder",
  async (order_id, { extra: { api }, rejectWithValue }) => {
    try {
      const data = await api.orders.findOrder(order_id);

      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

// запускает заказ в производство
export const submitProduction = createAsyncThunk<
  any,
  SubmitProductionInfo,
  ThunkAPI
>(
  "orders/submitProduction",
  async (body, { extra: { api }, rejectWithValue, dispatch }) => {
    const task_ids = body.tq_ids;
    const tc_id = body.tc_id;
    try {
      await api.orders.submitProduction(JSON.stringify(body));
      if (task_ids?.length && tc_id) {
        dispatch(getTaskHistory({ task_id: task_ids[0], tc_id }));
      }

      message.success(
        intl.formatMessage({
          id: "success.created-success",
          defaultMessage: "Created success",
        })
      );
      return body.tq_ids;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

export const pauseProduction = createAsyncThunk<
  number,
  SubmitProductionInfo,
  ThunkAPI
>(
  "orders/pauseProduction",
  async (body, { extra: { api }, rejectWithValue, dispatch }) => {
    const task_ids = body.tq_ids;
    const tc_id = body.tc_id;
    try {
      await api.orders.pauseProduction(JSON.stringify(body));
      if (task_ids?.length && tc_id) {
        dispatch(getTaskHistory({ task_id: task_ids[0], tc_id }));
      }

      message.success(
        intl.formatMessage({
          id: "success.updated-success",
          defaultMessage: "Updated success",
        })
      );

      const ids = body.tq_ids!;

      return ids[0];
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

//  Меняет статус комплектования материалами для ЗнП
export const setOrderSupplyStatus = createAsyncThunk<
  { task_id: number; status: ProdOrderSupplyStatus },
  {
    task_id: number;
    body: {
      status: ProdOrderSupplyStatus;
      tc_id: number;
    };
  },
  ThunkAPI
>(
  "orders/setOrderSupplyStatus",
  async ({ task_id, body }, { extra: { api }, rejectWithValue, dispatch }) => {
    const tc_id = body.tc_id;
    try {
      await api.orders.setOrderSupplyStatus(task_id, JSON.stringify(body));

      dispatch(getTaskHistory({ task_id, tc_id }));

      message.success(
        intl.formatMessage({
          id: "success.updated-success",
          defaultMessage: "Updated success",
        })
      );

      return { task_id, status: body.status };
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

// Позволяет создать ЗнП из заказа покупателя
export const сreateProductionOrder = createAsyncThunk<
  null,
  StartOrderInfo,
  ThunkAPI
>(
  "orders/сreateProductionOrder",
  async (body, { extra: { api }, rejectWithValue, dispatch }) => {
    try {
      await api.orders.сreateProductionOrder(JSON.stringify(body));
      dispatch(getClientOrderHistory(body.order_id));

      message.success(
        intl.formatMessage({
          id: "success.created-success",
          defaultMessage: "Created success",
        })
      );

      return null;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

// Изменяет статус согласования
export const changeApproveState = createAsyncThunk<
  boolean,
  {
    order_id: string;
    status: boolean;
  },
  ThunkAPI
>(
  "orders/changeApproveState",
  async (
    { order_id, status },
    { extra: { api }, rejectWithValue, dispatch }
  ) => {
    try {
      await api.orders.changeApproveState(order_id, status);
      dispatch(getClientOrderHistory(order_id));

      message.success(
        intl.formatMessage({
          id: "success.updated-success",
          defaultMessage: "Updated success",
        })
      );

      return status;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

// Возвращает список компонентов для заказа на производство
export const getProdComponentsList = createAsyncThunk<
  Array<ComponentInDb>,
  {
    task_id: number;
    tc_id: number;
  },
  ThunkAPI
>(
  "orders/getProdComponentsList",
  async ({ task_id, tc_id }, { extra: { api }, rejectWithValue }) => {
    try {
      const data = await api.orders.getProdComponentsList(task_id, tc_id);

      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

// Возвращает список компонентов для заказа на производство
export const getProdComponentsListXLSX = createAsyncThunk<
  null,
  {
    task_id: number;
    tc_id: number;
  },
  ThunkAPI
>(
  "orders/getProdComponentsListXLSX",
  async ({ task_id, tc_id }, { extra: { api }, rejectWithValue }) => {
    const name = `ЗнП-${task_id.toString().padStart(5, "0")}.xlsx`;
    try {
      const data = await api.orders.getProdComponentsList(
        task_id,
        tc_id,
        "xlsx"
      );
      var xlsxURL = window.URL.createObjectURL(data);
      let tempLink = document.createElement("a");
      tempLink.href = xlsxURL;
      tempLink.setAttribute("download", name);
      tempLink.click();
      return null;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

// Возвращает список компонентов для заказа покупателя
export const getOrderComponentsList = createAsyncThunk<
  Array<ComponentInDb>,
  string,
  ThunkAPI
>(
  "orders/getOrderComponentsList",
  async (task_id, { extra: { api }, rejectWithValue }) => {
    try {
      const data = await api.orders.getOrderComponentsList(task_id);

      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

// Возвращает список компонентов для заказа на производство
export const getOrderComponentsListXLSX = createAsyncThunk<
  null,
  string,
  ThunkAPI
>(
  "orders/getOrderComponentsListXLSX",
  async (order_id, { extra: { api }, rejectWithValue }) => {
    const name = `ЗП-${order_id.padStart(5, "0")}.xlsx`;
    try {
      const data = await api.orders.getOrderComponentsList(order_id, "xlsx");
      var xlsxURL = window.URL.createObjectURL(data);
      let tempLink = document.createElement("a");
      tempLink.href = xlsxURL;
      tempLink.setAttribute("download", name);
      tempLink.click();
      return null;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

// Чистим список компонентов в сторе
export const cleanMaterials = createAction("orders/cleanMaterials");

// Возвращает список компонентов для списка заказов покупателя
export const getClientOrderComponentsList = createAsyncThunk<
  Array<ComponentInDb>,
  "all" | "approved",
  ThunkAPI
>(
  "orders/getClientOrderComponentsList",
  async (filter, { extra: { api }, rejectWithValue }) => {
    try {
      const data = await api.orders.getClientOrderComponentsList(filter);

      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

// Возвращает список компонентов для списка заказов покупателя
export const getClientOrderComponentsListXLSX = createAsyncThunk<
  null,
  "all" | "approved",
  ThunkAPI
>(
  "orders/getClientOrderComponentsListXLSX",
  async (filter, { extra: { api }, rejectWithValue }) => {
    const name = `Потребность в материалах.xlsx`;
    try {
      const data = await api.orders.getClientOrderComponentsList(
        filter,
        "xlsx"
      );
      var xlsxURL = window.URL.createObjectURL(data);
      let tempLink = document.createElement("a");
      tempLink.href = xlsxURL;
      tempLink.setAttribute("download", name);
      tempLink.click();
      return null;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

// Возвращает список компонентов для списка заказов на производство
export const getProdOrderComponentsList = createAsyncThunk<
  Array<ComponentInDb>,
  "all" | "not_in_production" | "not_stuffed",
  ThunkAPI
>(
  "orders/getProdOrderComponentsList",
  async (filter, { extra: { api }, rejectWithValue, getState }) => {
    const tc_id = getState().orders.currentTechCell;
    try {
      const data = await api.orders.getProdOrderComponentsList(filter, tc_id);

      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

// Возвращает список компонентов для списка заказов на производство
export const getProdOrderComponentsListXLSX = createAsyncThunk<
  null,
  "all" | "not_in_production" | "not_stuffed",
  ThunkAPI
>(
  "orders/getProdOrderComponentsListXLSX",
  async (filter, { extra: { api }, rejectWithValue, getState }) => {
    const name = `Потребность в материалах.xlsx`;
    const tc_id = getState().orders.currentTechCell;
    try {
      const data = await api.orders.getProdOrderComponentsList(
        filter,
        tc_id,
        "xlsx"
      );
      var xlsxURL = window.URL.createObjectURL(data);
      let tempLink = document.createElement("a");
      tempLink.href = xlsxURL;
      tempLink.setAttribute("download", name);
      tempLink.click();
      return null;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

// Возвращает список номеклатур
export const getNomenclature = createAsyncThunk<
  Array<NomHierarchy>,
  undefined,
  ThunkAPI
>("storage/getNomenclature", async (_, { extra: { api }, rejectWithValue }) => {
  try {
    const data = await api.orders.getNomenclature();

    return data;
  } catch (error) {
    console.error(error);
    return rejectWithValue(error);
  }
});

// удаляем заказ покупателя
export const deleteOrder = createAsyncThunk<null, string, ThunkAPI>(
  "orders/deleteOrder",
  async (order_id, { extra: { api }, rejectWithValue }) => {
    try {
      await api.orders.deleteOrder(order_id);
      history.push("/orders/customer-orders");

      return null;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

// Изменяет заказ (Требуются все поля)
export const putOrder = createAsyncThunk<
  null,
  {
    order_id: string;
    order: NewClientOrder;
  },
  ThunkAPI
>(
  "orders/putOrder",
  async ({ order_id, order }, { extra: { api }, rejectWithValue }) => {
    const body = JSON.stringify(order);
    try {
      await api.orders.putOrder(order_id, body);

      message.success(
        intl.formatMessage({
          id: "success.updated-success",
          defaultMessage: "Updated success",
        })
      );

      return null;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

// Получить кол-во ЗП и ЗнП
export const getQuantity = createAsyncThunk<QuantityInfo, undefined, ThunkAPI>(
  "orders/getQuantity",
  async (_, { extra: { api }, rejectWithValue }) => {
    try {
      const data = await api.orders.getQuantity();

      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);
