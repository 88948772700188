import { useSelector } from "app/hooks";
import { FormattedMessage } from "react-intl";

// styles
import style from "./PartnerSelect.module.css";

interface IProps {
  isAdminPages: boolean | undefined;
}

export const PartnerSelect: React.FC<IProps> = ({ isAdminPages }) => {
  const currentPartner = useSelector((state) => state.partners.currentPartner);

  if (isAdminPages) {
    return (
      <div className={style.box}>
        <span className={style.title}>
          <FormattedMessage
            id="app.main-menu-administration"
            defaultMessage="Administration"
          />
        </span>
      </div>
    );
  }

  if (!currentPartner) {
    return (
      <div className={style.box}>
        <span className={style.title}>
          <FormattedMessage
            id="app.production-control"
            defaultMessage="Production control"
          />
        </span>
      </div>
    );
  }

  return (
    <div className={style.box}>
      <span className={style.title}>{currentPartner.name}</span>
    </div>
  );
};
