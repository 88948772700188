import { createSlice } from "@reduxjs/toolkit";
import { intl } from "lib/locale";

// actions
import { getUsers } from "../actions";

// types
import { IError } from "types/type-def";
import { AllUsersInfo } from "types";

interface IInitialState {
  users: Array<AllUsersInfo>;
  loading: boolean;
  error: IError;
}

const initialState: IInitialState = {
  users: [],
  loading: false,
  error: null,
};

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Возвращает информацию о всех пользователях
    builder.addCase(getUsers.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getUsers.fulfilled, (state, action) => {
      state.loading = false;
      state.users = action.payload;
    });
    builder.addCase(getUsers.rejected, (state) => {
      state.loading = false;
      state.error = intl.formatMessage({
        id: "error.loading-error",
        defaultMessage: "An error occurred while loading",
      });
    });
  },
});
