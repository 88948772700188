import { useSelector } from "app/hooks";
import { Link, useLocation } from "react-router-dom";

// components
import { Layout, Typography, Space } from "antd";

import { UserMenu } from "../UserMenu";
import { PartnerSelect } from "common/components/PartnerSelect";
import { PartnersButton, AdminButton } from "common/components/UserMenu";

// style
import style from "./Header.module.css";

const { Header: AHeader } = Layout;
const { Text } = Typography;

export const Header: React.FC = () => {
  const location = useLocation();

  const currentUser = useSelector((state) => state.currentUser.user);

  const isSuperAdmin = currentUser?.is_super_admin;
  const isAdminPages = isSuperAdmin && location.pathname.includes("admin");

  return (
    <AHeader className={style.header}>
      <Space direction="horizontal">
        <div className={style.titleBox}>
          <Link to="/">
            <Text className={style.erpTitle}>iLEDea</Text>
          </Link>
        </div>

        <PartnerSelect isAdminPages={isAdminPages} />
      </Space>

      <div></div>

      <div className={style.box}>
        <AdminButton />

        <PartnersButton />

        <UserMenu />
      </div>
    </AHeader>
  );
};
