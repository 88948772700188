import { request } from "lib/api";

// types
import { handleErrors } from "lib/handleErrors";
import { Spec_deprecated } from "types";

// Запрос списка клиентов для текущего партнера
const getCounterparty = async () => {
  const resp = await request("/counterparty", "GET");

  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

// создание нового клиента
const createCounterparty = async (body: string) => {
  const resp = await request("/counterparty", "POST", body);

  if (!resp.ok) {
    handleErrors(resp);
  }
  return null;
};

// создание нового клиента
const updateCounterparty = async (id: string, body: string) => {
  const resp = await request(`/counterparty/${id}`, "PUT", body);

  if (!resp.ok) {
    handleErrors(resp);
  }
  return null;
};

// удаление клиента
const deleteCounterparty = async (id: number) => {
  const resp = await request(`/counterparty/${id}`, "DELETE");

  if (!resp.ok) {
    handleErrors(resp);
  }
  return null;
};

// Меняет названия у выбраных номенклатур
const setNomenclatureLocalName = async (body: string) => {
  const resp = await request("/nomenclature/name", "PUT", body);

  if (!resp.ok) {
    handleErrors(resp);
  }
  return null;
};

// Получить спецификацию
const getSpec = async (product_id: number): Promise<Spec_deprecated> => {
  const resp = await request(`/nomenclature/${product_id}/spec`, "GET");

  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

// Отредактировать спецификацию
const patchSpec = async (product_id: number, body: string) => {
  const resp = await request(`/nomenclature/${product_id}/spec`, "PATCH", body);

  if (!resp.ok) {
    handleErrors(resp);
  }
  return null;
};

// Скопировать спецификацию
const copySpec = async (product_id: number, body: string) => {
  const resp = await request(`/copy_spec/${product_id}`, "PATCH", body);

  if (!resp.ok) {
    const data = await resp.json();
    return data;
  }
  return null;
};

const settings = {
  getCounterparty,
  createCounterparty,
  updateCounterparty,
  deleteCounterparty,
  setNomenclatureLocalName,
  getSpec,
  patchSpec,
  copySpec,
};

export default settings;
