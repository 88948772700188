import { createSlice } from "@reduxjs/toolkit";
import { intl } from "lib/locale";

// actions
import { getQuantity } from "../actions";

// types
import { QuantityInfo } from "types";
import { IError, Maybe } from "types/type-def";

interface IInitialState {
  quantity: Maybe<QuantityInfo>;
  error: IError;
}

const initialState: IInitialState = {
  quantity: null,
  error: null,
};

export const ordersQuantitySlice = createSlice({
  name: "orders",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Получить кол-во ЗП и ЗнП
    builder.addCase(getQuantity.pending, (state) => {
      state.error = null;
    });
    builder.addCase(getQuantity.fulfilled, (state, action) => {
      state.quantity = action.payload;
    });
    builder.addCase(getQuantity.rejected, (state) => {
      state.error = intl.formatMessage({
        id: "error.loading-error",
        defaultMessage: "An error occurred while loading",
      });
    });
  },
});
