import { ReactNode } from "react";
import { useSelector } from "app/hooks";
import { useLocation, Link } from "react-router-dom";

// components
import { Layout, Typography } from "antd";

import { Header } from "../Header";
import { MainMenu } from "../MainMenu";
import { SuperAdminMenu } from "../SuperAdminMenu";
import { MobileMainMenu } from "../MobileMainMenu";

// styles
import style from "./AppLayout.module.css";

const { Content, Sider } = Layout;
const { Text } = Typography;

interface IProps {
  children: ReactNode;
}

export const withMenuLayout = (Page: any) => {
  const innerPage = (props: any, layoutProps: any) => (
    <AppLayout {...layoutProps}>
      <Page {...props} />
    </AppLayout>
  );
  return innerPage;
};

const AppLayout: React.FC<IProps> = ({ children }) => {
  const location = useLocation();

  const currentPartner = useSelector((state) => state.partners.currentPartner);
  const currentUser = useSelector((state) => state.currentUser.user);

  const isAdminPage = location.pathname.includes("admin");
  const isAdmin = isAdminPage && currentUser?.is_super_admin;

  const getMenu = (isMobile?: boolean) => {
    if (currentPartner && !isAdmin) {
      return <MainMenu theme={isMobile ? "light" : "dark"} />;
    }
    if (isAdmin) {
      return <SuperAdminMenu />;
    }
  };

  return (
    <Layout className={style.appLayout}>
      <Sider className={style.sider}>
        <div className={style.titleBox}>
          <Link to="/">
            <Text className={style.erpTitle}>iLEDea</Text>
          </Link>
        </div>

        <div>{getMenu()}</div>
      </Sider>

      <Layout>
        <Header />

        <Content className={style.content}>
          <MobileMainMenu
            getMenu={getMenu}
            isAdmin={isAdmin}
            isPartnerChecked={!!currentPartner}
          />

          {children}
        </Content>
      </Layout>
    </Layout>
  );
};
