import { useSelector } from "app/hooks";
import { intl } from "lib/locale";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

// components
import { Badge } from "antd";

// utils
import { RoutesEnum } from "utils/useRoutes";

// types
import { MenuProps } from "antd/lib/menu";

type MenuItem = Required<MenuProps>["items"][number];
type IMenuItem = MenuItem & { access: boolean; key: React.Key };

export const MenuItems = (routes: Record<string, boolean>) => {
  const quantity = useSelector((state) => state.ordersQuantity.quantity);

  const checkAccess = (items: IMenuItem[]) =>
    items.filter(({ access }) => access);

  const items = checkAccess([
    // Блок ПРОИЗВОДСТВО
    {
      label: intl.formatMessage({
        id: "app.main-menu-production",
        defaultMessage: "Production",
      }),
      key: "orders",
      type: "group",
      access: routes[RoutesEnum.GET_ORDERS],
      children: checkAccess([
        {
          label: (
            <Link to="/orders/customer-orders">
              <FormattedMessage
                id="orders.customer-orders-title"
                defaultMessage="Customer orders"
              />

              {quantity && (
                <Badge
                  count={quantity.quantity_orders}
                  className="sorter-badge"
                />
              )}
            </Link>
          ),
          key: "/orders/customer-orders",
          access: routes[RoutesEnum.GET_ORDERS],
        },
        {
          label: (
            <Link to="/orders/production-orders">
              <FormattedMessage
                id="orders.production-orders-title"
                defaultMessage="Production orders"
              />

              {quantity && (
                <Badge
                  count={quantity.quantity_tasks}
                  className="sorter-badge"
                />
              )}
            </Link>
          ),
          key: "/orders/production-orders",
          access: routes[RoutesEnum.GET_ORDERS],
        },
        {
          label: (
            <Link to="/production/tasks">
              <FormattedMessage id="tasks.tasks-title" defaultMessage="Tasks" />
            </Link>
          ),
          key: "/production/tasks",
          access:
            routes[RoutesEnum.GET_STARTED_TASKS] &&
            routes[RoutesEnum.GET_TC_USERS],
        },
        {
          label: (
            <Link to="/production/work-shifts">
              <FormattedMessage
                id="app.main-menu-work-shifts"
                defaultMessage="Work Shifts"
              />
            </Link>
          ),
          key: "/production/work-shifts",
          access: routes[RoutesEnum.GET_TECHCELL_CALENDAR],
        },
        // {
        //   label: (
        //     <Link to="/orders/production-schedule">
        //       <FormattedMessage
        //         id="app.main-menu-forecast"
        //         defaultMessage="Forecast"
        //       />
        //     </Link>
        //   ),
        //   key: "/orders/production-schedule",
        //   access: routes[RoutesEnum.GET_ORDERS],
        // },
      ]),
    },

    // блок ОТЧЕТЫ
    {
      label: intl.formatMessage({
        id: "app.main-menu-reports",
        defaultMessage: "Reports",
      }),
      key: "reports",
      type: "group",
      access:
        routes[RoutesEnum.GET_WORKING_TIME] ||
        routes[RoutesEnum.GET_REPORTS_SALARY] ||
        routes[RoutesEnum.GET_PRODUCED_PRODUCTS] ||
        (routes[RoutesEnum.GET_CLIENT_ORDERS_COMPONENTS] &&
          routes[RoutesEnum.GET_PRODUCTION_ORDERS_COMPONENTS]),
      children: checkAccess([
        {
          label: (
            <Link to="/reports/working-time">
              <FormattedMessage
                id="reports.title-working-time"
                defaultMessage="Working time"
              />
            </Link>
          ),
          key: "/reports/working-time",
          access: routes[RoutesEnum.GET_WORKING_TIME],
        },
        {
          label: (
            <Link to="/reports/production-salary">
              <FormattedMessage
                id="reports.title-production-salary"
                defaultMessage="Production salary"
              />
            </Link>
          ),
          key: "/reports/production-salary",
          access: routes[RoutesEnum.GET_REPORTS_SALARY],
        },
        {
          label: (
            <Link to="/reports/materials">
              <FormattedMessage
                id="orders.need-for-materials"
                defaultMessage="Need for materials"
              />
            </Link>
          ),
          key: "/reports/materials",
          access:
            routes[RoutesEnum.GET_CLIENT_ORDERS_COMPONENTS] &&
            routes[RoutesEnum.GET_PRODUCTION_ORDERS_COMPONENTS],
        },
        {
          label: (
            <Link to="/reports/produced-products">
              <FormattedMessage
                id="reports.produced-products"
                defaultMessage="Produced products"
              />
            </Link>
          ),
          key: "/reports/produced-products",
          access: routes[RoutesEnum.GET_PRODUCED_PRODUCTS],
        },
      ]),
    },

    // блок ИНСТРУМЕНТЫ
    // {
    //   label: intl.formatMessage({
    //     id: "app.main-menu-tools",
    //     defaultMessage: "Tools",
    //   }),
    //   key: "tools",
    //   type: "group",
    //   access: routes[RoutesEnum.GET_PRINT_INFO],
    //   children: checkAccess([
    //     {
    //       label: (
    //         <Link to="/storage/printing-labels">
    //           <FormattedMessage
    //             id="app.main-menu-printing-labels"
    //             defaultMessage="Printing labels"
    //           />
    //         </Link>
    //       ),
    //       key: "/storage/printing-labels",
    //       access: routes[RoutesEnum.GET_PRINT_INFO],
    //     },
    //   ]),
    // },

    // блок СОТРУДНИКИ
    {
      label: intl.formatMessage({
        id: "app.main-menu-users",
        defaultMessage: "Staff",
      }),
      key: "users",
      type: "group",
      access: routes[RoutesEnum.GET_USERS] || routes[RoutesEnum.GET_DAYS_OFF],
      children: checkAccess([
        {
          label: (
            <Link to="/users">
              <FormattedMessage
                id="app.main-menu-users"
                defaultMessage="Staff"
              />
            </Link>
          ),
          key: "/users",
          access: routes[RoutesEnum.GET_USERS],
        },
        {
          label: (
            <Link to="/users/days-off">
              <FormattedMessage
                id="app.main-menu-days-off"
                defaultMessage="Holidays and days off"
              />
            </Link>
          ),
          key: "/users/days-off",
          access: routes[RoutesEnum.GET_DAYS_OFF],
        },
      ]),
    },

    // блок НАСТРОЙКИ
    {
      label: intl.formatMessage({
        id: "app.main-menu-settings",
        defaultMessage: "Settings",
      }),
      key: "settings",
      type: "group",
      access:
        routes[RoutesEnum.GET_WORK_SHEDULES] ||
        routes[RoutesEnum.GET_CALENDARS] ||
        routes[RoutesEnum.GET_COUNTERPARTY] ||
        routes[RoutesEnum.GET_NOMENCLATURE_SPEC],
      children: checkAccess([
        {
          label: (
            <Link to="/settings/clients">
              <FormattedMessage
                id="app.main-menu-clients"
                defaultMessage="Clients"
              />
            </Link>
          ),
          key: "/settings/clients",
          access: routes[RoutesEnum.GET_COUNTERPARTY],
        },
        {
          label: (
            <Link to="/settings/costs?mode=name">
              <FormattedMessage
                id="products-management.main-title"
                defaultMessage="Products management"
              />
            </Link>
          ),
          key: "/settings/costs",
          access: routes[RoutesEnum.GET_NOMENCLATURE_SPEC],
        },
        {
          label: (
            <Link to="/users/calendars">
              <FormattedMessage
                id="app.main-menu-calendars"
                defaultMessage="Calendars"
              />
            </Link>
          ),
          key: "/users/calendars",
          access: routes[RoutesEnum.GET_CALENDARS],
        },
        {
          label: (
            <Link to="/users/work-schedules">
              <FormattedMessage
                id="app.main-menu-work-schedules"
                defaultMessage="Work schedules"
              />
            </Link>
          ),
          key: "/users/work-schedules",
          access: routes[RoutesEnum.GET_WORK_SHEDULES],
        },
      ]),
    },
  ]);

  return items;
};
