import { useDispatch, useSelector } from "app/hooks";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

// components
import { Avatar, Menu, Dropdown } from "antd";

// actions
import { logout } from "feat/auth/actions";

// styles
import style from "./UserMenu.module.css";

export const UserMenu: React.FC = () => {
  const dispatch = useDispatch();

  const currentUser = useSelector((state) => state.currentUser.user);

  const fullname = currentUser?.fullname ?? "";
  const name = fullname?.split(" ")[0] ?? "";
  const surname = fullname?.split(" ")[1] ?? "";
  const init = `${name[0] ?? ""}${surname[0] ?? ""}`;

  const onLogoutClick = async () => {
    dispatch(logout());
  };

  const menu = (
    <Menu>
      <Menu.Item key="1">
        <Link to="/users/profile">
          <FormattedMessage
            id="app.header-menu-profile"
            defaultMessage="Profile"
          />
        </Link>
      </Menu.Item>

      <Menu.Divider />

      <Menu.Item key="3" onClick={onLogoutClick}>
        <FormattedMessage
          id="app.header-menu-logout"
          defaultMessage="Log out"
        />
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} placement="bottomRight" trigger={["click"]}>
      <div className={style.headerBlocks}>
        <Avatar size="small">{init}</Avatar>

        <span className={style.userName}>{fullname}</span>
      </div>
    </Dropdown>
  );
};
