// utils
import { isDevelopment } from "utils/env";

export const websocket = (currentPartnerId?: number) => {
  const host = isDevelopment
    ? "ws://erp-test.tiram.icu"
    : `${document.location.protocol === "https:" ? "wss" : "ws"}://${
        document.location.host
      }`;

  const ws = new WebSocket(
    `${host}/api/ws/v1/updates?partner_code=${currentPartnerId}`
  );

  return ws;
};
