import { createSlice } from "@reduxjs/toolkit";

// actions
import {
  togglePartner,
  createPartner,
  deletePartner,
  updatePartner,
} from "../actions";
import { getCurrentUser } from "feat/user/actions";

// types
import { IError, Maybe } from "types/type-def";
import { PartnerDB } from "types";

interface IInitialState {
  currentPartner: Maybe<PartnerDB>;
  partners: Array<PartnerDB>;

  error: IError;
}

const initialState: IInitialState = {
  currentPartner: null,
  partners: [],

  error: null,
};

export const partnersSlice = createSlice({
  name: "partners",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // берем список партнеров у текущего юзера
    builder.addCase(getCurrentUser.fulfilled, (state, action) => {
      const partners = action.payload.partners;
      state.partners =
        partners?.slice().sort((a, b) => a.name.localeCompare(b.name)) ?? [];
    });

    // меняем партнера
    builder.addCase(togglePartner, (state, action) => {
      state.currentPartner = action.payload;
    });

    // создаем нового партнера
    builder.addCase(createPartner.fulfilled, (state, action) => {
      state.partners = action.payload;
    });

    // удаляем партнера
    builder.addCase(deletePartner.fulfilled, (state, action) => {
      state.partners = action.payload;
    });

    // редактируем партнера
    builder.addCase(updatePartner.fulfilled, (state, action) => {
      state.partners = action.payload;
    });
  },
});
