import { useEffect } from "react";
import { useSelector, useDispatch } from "app/hooks";
import { useLocation } from "react-router-dom";
import "antd/dist/antd.min.css";
import "moment-duration-format";

// components
import { Loading } from "common/components/Loading";
import { Routes } from "./Routes";
import { ErrorBoundary } from "common/components/ErrorBoundary";

// utils
import { history } from "utils/history";
import { useRoutes, RoutesEnum } from "utils/useRoutes";

// actions
import { getRoutes } from "feat/user/actions";
import { togglePartner } from "feat/admin/actions";
import { getQuantity } from "feat/orders/actions";

// styles
import "common/style.css";

export const App = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const loggedUser = useSelector((state) => state.currentUser.loggedUser);
  const partnersList = useSelector((state) => state.partners.partners);
  const loading = useSelector((state) => state.currentUser.loading);
  const loadingRoutes = useSelector((state) => state.routes.loading);

  const isOrdersQuantityAllowed = useRoutes(RoutesEnum.GET_QUANTITY);

  useEffect(() => {
    const currentPartnerId = localStorage.getItem("partner");
    if (
      !currentPartnerId &&
      loggedUser &&
      location.pathname !== "/" &&
      !location.pathname.includes("/admin") &&
      location.pathname !== "/users/profile"
    ) {
      history.push("/");
    }
  }, [location.pathname, loggedUser]);

  useEffect(() => {
    if (loggedUser) {
      dispatch(getRoutes());
    }
  }, [dispatch, loggedUser]);

  useEffect(() => {
    const currentPartnerId = localStorage.getItem("partner");

    if (partnersList.length) {
      const currentPartner =
        partnersList.find(({ id }) => id?.toString() === currentPartnerId) ??
        null;
      dispatch(togglePartner(currentPartner));
    }
  }, [dispatch, partnersList]);

  useEffect(() => {
    const currentPartnerId = localStorage.getItem("partner");
    if (currentPartnerId && isOrdersQuantityAllowed) {
      dispatch(getQuantity());
    }
  }, [dispatch, isOrdersQuantityAllowed]);

  if (loading || loadingRoutes) {
    return <Loading />;
  }

  return (
    <ErrorBoundary>
      <Routes />
    </ErrorBoundary>
  );
};
