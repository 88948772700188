import { useDispatch } from "app/hooks";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

// actions
import { togglePartner } from "feat/admin/actions";

// utils
import { history } from "utils/history";

// styles
import style from "./UserMenu.module.css";

export const PartnersButton: React.FC = () => {
  const dispatch = useDispatch();

  const onChangeGroup = () => {
    history.push("/");
    dispatch(togglePartner(null));
    localStorage.removeItem("partner");
  };

  return (
    <div className={style.headerBlocks}>
      <Link className={style.userName} to="/" onClick={onChangeGroup}>
        <FormattedMessage id="partner.partners" defaultMessage="Partners" />
      </Link>
    </div>
  );
};
