import admin from "feat/admin/api";
import auth from "feat/auth/api";
import orders from "feat/orders/api";
import production from "feat/production/api";
import reports from "feat/reports/api";
import settings from "feat/settings/api";
// import storage from "feat/storage/api";
import users from "feat/user/api";

const api = {
  admin,
  auth,
  orders,
  production,
  settings,
  users,
  reports,
};

export default api;
export { request } from "./request";
export type IApi = typeof api;
