import { createSlice } from "@reduxjs/toolkit";

// actions
import { getPs, getUserPs, getTCUsers, getTCCalendar } from "../actions";

// utils
import { intl } from "lib/locale";

// types
import {
  ProductionSubtaskTableRowTc,
  ProductionSubtaskTableRowUser,
} from "types";
import { IError, ITCCalendar, ITCUser } from "types/type-def";

interface IInitialState {
  tasks: Array<ProductionSubtaskTableRowTc>;
  userPs: Array<ProductionSubtaskTableRowUser>;
  tcUsers: Record<string, ITCUser>;
  tcCalendar: ITCCalendar;

  loading: boolean;
  error: IError;
}

const initialState: IInitialState = {
  tasks: [],
  userPs: [],
  tcUsers: {},
  tcCalendar: {},

  loading: false,
  error: null,
};

export const productionSlice = createSlice({
  name: "production",
  initialState,
  reducers: {
    // setStartedTasks: (state, action) => {
    //   state.tasks = action.payload.filter(
    //     (item) => item.tc_id.toString() === state.currentTechCell
    //   );
    // },
  },
  extraReducers: (builder) => {
    // Получить наряд-задания
    builder.addCase(getPs.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getPs.fulfilled, (state, action) => {
      state.loading = false;
      state.tasks = action.payload;
    });
    builder.addCase(getPs.rejected, (state) => {
      state.loading = false;
      state.error = intl.formatMessage({
        id: "error.loading-error",
        defaultMessage: "An error occurred while loading",
      });
    });

    // Получить наряд-задания пользователя
    builder.addCase(getUserPs.fulfilled, (state, action) => {
      state.loading = false;
      state.userPs = action.payload;
    });

    // Возвращает id и ФИО работников технологической ячейки
    builder.addCase(getTCUsers.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getTCUsers.fulfilled, (state, action) => {
      state.loading = false;
      state.tcUsers = action.payload;
    });
    builder.addCase(getTCUsers.rejected, (state) => {
      state.loading = false;
      state.error = intl.formatMessage({
        id: "error.loading-error",
        defaultMessage: "An error occurred while loading",
      });
    });

    // Возвращает календарь пользователей для технологической ячейки.
    builder.addCase(getTCCalendar.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getTCCalendar.fulfilled, (state, action) => {
      state.loading = false;
      state.tcCalendar = action.payload;
    });
    builder.addCase(getTCCalendar.rejected, (state) => {
      state.loading = false;
      state.error = intl.formatMessage({
        id: "error.loading-error",
        defaultMessage: "An error occurred while loading",
      });
    });
  },
});
