import { createAsyncThunk } from "@reduxjs/toolkit";

// utils
import { history } from "utils/history";
import { getCurrentUser, getRoutes } from "feat/user/actions";

// types
import { UserToken } from "types";
import { ThunkAPI } from "types/type-def";

export const login = createAsyncThunk<null, UserToken, ThunkAPI>(
  "auth/login",
  async (
    { username, password },
    { dispatch, extra: { api }, rejectWithValue }
  ) => {
    try {
      await api.auth.getToken({ username, password });
      await dispatch(getCurrentUser());
      await dispatch(getRoutes());
      return null;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

export const logout = createAsyncThunk<null, undefined, ThunkAPI>(
  "auth/logout",
  async (_, { extra: { api }, rejectWithValue }) => {
    try {
      await api.auth.logout();

      localStorage.removeItem("username");
      localStorage.removeItem("partner");

      history.push("/login");

      return null;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);
