import { array, string } from "yup";
import { intl } from "lib/locale";

export const passwordSchema = () => string().min(4).max(16).required();

export const usernameSchema = () => string().min(2).max(25).required();

export const confirmPasswordSchema = () =>
  passwordSchema().test(
    "confirm",
    intl.formatMessage({
      id: "validation.password.notmatch",
      defaultMessage: "Password mismatch",
    }),
    function test(confirm) {
      return confirm === this.parent.password;
    }
  );

export const getTechCellsSchema = () =>
  array().test("access", "no data", function test() {
    if (this.parent.access === "choose" && this.parent.techcells.length !== 0) {
      return true;
    }
    if (this.parent.access === "all") {
      return true;
    }
    return false;
  });

export const partnersSchema = () =>
  array().test("access", "no data", function test() {
    console.log(this.parent.partners);
    if (this.parent.access === "choose" && this.parent.partner) {
      return true;
    }
    if (this.parent.access === "all") {
      return true;
    }
    return false;
  });

export const createUserSchema = (step: number) => {
  switch (step) {
    case 0:
      return {
        username: usernameSchema(),
        surname: usernameSchema(),
        name: usernameSchema(),
        email: string().email(),
      };
    // case 1:
    //   return {
    //     roles: array().min(1),
    //   };
    case 2:
      return {
        techcell: string().required(),
        schedule: string().required(),
        phone: string().required(),
      };
    case 3:
      return {
        password: passwordSchema(),
        confirm: confirmPasswordSchema(),
      };
    default:
      return {};
  }
};
