import { request } from "lib/api";
import { handleErrors } from "lib/handleErrors";
import {
  NewUser,
  UpdatedUser,
  WorkingSchedule,
  WorkingScheduleBase,
} from "types";

interface IGetUserInput {
  id?: string;
  username?: string;
}

// Возвращает информацию о пользователе.
// Если не указать username или user_id - вернёт информацию о текущем пользователе.
const getUserInfo = async ({ id, username }: IGetUserInput) => {
  const currentUsername = localStorage.getItem("username");

  let searchParam;

  if (id) {
    searchParam = `user_id=${id}`;
  } else {
    searchParam = `username=${username || currentUsername}`;
  }

  const resp = await request(`/user-info?${searchParam}`, "GET");

  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

// Возвращает информацию о всех пользователях
const getUsers = async (add_unactive: boolean | undefined) => {
  const params = add_unactive ? `?add_unactive=${add_unactive}` : ``;
  const resp = await request(`/users${params}`, "GET");

  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

// Обновляет пользователя
const updateUser = async (updateData: UpdatedUser) => {
  const body = JSON.stringify(updateData);
  const resp = await request(`/update-user`, "POST", body);

  if (!resp.ok) {
    handleErrors(resp);
  }
  return null;
};

// создает пользователя
const createUser = async (inputData: NewUser) => {
  const body = JSON.stringify(inputData);
  //  const code = checkedPartner ? `?partner_code=${checkedPartner}` : "";
  const resp = await request("/create-user", "POST", body);

  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

// Выдаёт список доступных производственных календарей
const getCalendars = async () => {
  const resp = await request(`/calendars`, "GET");

  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

// Создание нового производственного календаря
const createCalendar = async (name: FormData) => {
  const currentPartner = localStorage.getItem("partner") ?? "";
  const resp = await fetch("/api/v1/calendars", {
    method: "POST",
    body: name,
    headers: {
      "Partner-code": currentPartner,
    },
  });

  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

// Проверка производственного календаря на корректность
// Возвращается календарь в случае если проверка не выявила ошибок,
// либо Status с описанием ошибки
const сheckCalendar = async (file: FormData) => {
  const currentPartner = localStorage.getItem("partner") ?? "";
  const resp = await fetch(`/api/v1/check-calendar`, {
    method: "POST",

    body: file,
    headers: {
      "Partner-code": currentPartner,
    },
  });

  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

// Изменение производственного календаря
const updateCalendar = async ({
  calendar_id,
  formdata,
}: {
  calendar_id: string;
  formdata: FormData;
}) => {
  const currentPartner = localStorage.getItem("partner") ?? "";
  const resp = await fetch(`/api/v1/calendars/${calendar_id}`, {
    method: "PATCH",

    body: formdata,
    headers: {
      "Partner-code": currentPartner,
    },
  });

  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

// Удаление календаря
const deleteCalendar = async (id: number) => {
  const resp = await request(`/calendars/${id}`, "DELETE");

  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

// список графиков работы
const getWorkingSchedules = async (
  partner_code?: string
): Promise<Array<WorkingSchedule>> => {
  const code = partner_code ? `?partner_code=${partner_code}` : "";
  const resp = await request(`/work-schedules${code}`, "GET");

  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

// создание рабочего графика
const createWorkingSchedule = async (body: string) => {
  const resp = await request(`/work-schedules`, "POST", body);

  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

// удаление рабочего графика
const deleteWorkingSchedule = async (working_schedule_id: number) => {
  const resp = await request(
    `/work-schedules/${working_schedule_id}`,
    "DELETE"
  );

  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

// обновление рабочего графика
const updateWorkingSchedule = async ({
  working_schedule_id,
  body,
}: {
  working_schedule_id: number;
  body: WorkingScheduleBase;
}) => {
  const resp = await request(
    `/work-schedules/${working_schedule_id}`,
    "PATCH",
    JSON.stringify(body)
  );

  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

// список отпуска и отгулы
const getDaysOff = async ({
  to_day,
  from_day,
  tc_id,
}: {
  to_day: string;
  from_day: string;
  tc_id: number;
}) => {
  let searchParam = `from_day=${from_day}&to_day=${to_day}&tc_id=${tc_id}`;

  const resp = await request(`/days-off?${searchParam}`, "GET");

  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

// Создаем отпуск/отгул
const createDayOff = async (body: string) => {
  const resp = await request(`/days-off`, "POST", body);

  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

// Удаляем отпуск/отгул
const deleteDayOff = async (day_off_id: number) => {
  const resp = await request(`/days-off/${day_off_id}`, "DELETE");

  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

// Обновляем отпуск/отгул
const updateDayOff = async ({
  day_off_id,
  body,
}: {
  day_off_id: number;
  body: string;
}) => {
  const resp = await request(`/days-off/${day_off_id}`, "PATCH", body);

  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

//Список доступных пользователю методов
const getRoutes = async () => {
  const resp = await request(`/routes`, "GET");

  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

const users = {
  getUserInfo,
  getUsers,
  updateUser,
  createUser,
  getCalendars,
  createCalendar,
  сheckCalendar,
  updateCalendar,
  getWorkingSchedules,
  createWorkingSchedule,
  deleteWorkingSchedule,
  updateWorkingSchedule,
  getDaysOff,
  createDayOff,
  deleteDayOff,
  updateDayOff,
  getRoutes,
  deleteCalendar,
};

export default users;
