import { request } from "lib/api";
import { handleErrors } from "lib/handleErrors";

// Получить наряд-задания
const getPs = async (tc_id: number) => {
  const resp = await request(`/started-tasks?tc_id=${tc_id}`, "GET");

  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

// Отвязывает задачу от работника
const unbindTask = async (tasks: string) => {
  const resp = await request(`/bind-task`, "DELETE", tasks);

  if (!resp.ok) {
    handleErrors(resp);
  }
  return null;
};

// Начинает выполнение задачи работником
const bindTask = async (tasks: string) => {
  const resp = await request(`/bind-task`, "PUT", tasks);

  if (!resp.ok) {
    handleErrors(resp);
  }
  return null;
};

// Получить наряд-задания пользователя
const getUserPs = async (user_id: number) => {
  const resp = await request(`/user-tasks/${user_id}`, "GET");

  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

// Возвращает id и ФИО работников технологической ячейки
const getTCUsers = async (tc_id: number) => {
  let searchParam = `tc_id=${tc_id}`;
  const resp = await request(`/techcell-users?${searchParam}`, "GET");

  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

// Возвращает календарь пользователей для технологической ячейки.
const getTCCalendar = async ({
  tc_id,
  to_day,
  from_day,
}: Record<string, string>) => {
  let searchParam = `from_day=${from_day}&to_day=${to_day}&tc_id=${tc_id}`;

  const resp = await request(`/techcell-calendar?${searchParam}`, "GET");

  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

const setUserCalendar = async (body: string) => {
  const resp = await request(`/user-worktime-on-date`, "POST", body);

  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

const tasks = {
  getPs,
  unbindTask,
  bindTask,
  getUserPs,
  getTCUsers,
  getTCCalendar,
  setUserCalendar,
};

export default tasks;
