import React from "react";
import { FormattedMessage } from "react-intl";
import { intl } from "lib/locale";
import { Helmet } from "react-helmet-async";

// components
import { Result, Button } from "antd";

// utils
import store from "app/store";
import { history } from "utils/history";
import { isProduction } from "utils/env";
import { getMenuItem } from "utils/useRoutes";

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  backClick = () => {
    history.push("/");
    window.location.reload();
  };

  homeClick = () => {
    const routes = store.getState().routes.routes;
    const { menuItem } = getMenuItem(routes);
    history.push(menuItem);
    window.location.reload();
  };

  componentDidCatch(error, errorInfo) {
    this.setState({ error, errorInfo });
    if (isProduction) {
      setTimeout(() => this.backClick(), 4000);
    }
  }

  render() {
    if (this.state.error) {
      return (
        <>
          <Helmet>
            <title>
              {intl.formatMessage({
                id: "error.error",
                defaultMessage: "Error",
              })}
            </title>
          </Helmet>

          <Result
            status="500"
            title={
              <FormattedMessage
                id="error.has-error"
                defaultMessage="An error has occurred"
              />
            }
            subTitle={
              <FormattedMessage
                id="error.try-refreshing-msg"
                defaultMessage="Try refreshing the page"
              />
            }
            extra={
              <div>
                <Button type="primary" onClick={() => this.backClick()}>
                  <FormattedMessage id="app.main" defaultMessage="Main" />
                </Button>
              </div>
            }
          />
        </>
      );
    }

    return this.props.children;
  }
}
