import { useEffect } from "react";
import { useFormik, FormikProvider } from "formik";
import { useHistory } from "react-router-dom";
import { object } from "yup";
import { useDispatch, useSelector } from "app/hooks";
import { FormattedMessage, useIntl } from "react-intl";
import { Helmet } from "react-helmet-async";

//components
import { Input, FormItem } from "formik-antd";
import { Button, Form, Typography } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";

// styles
import style from "./LoginForm.module.css";

//utils
import {
  usernameSchema,
  passwordSchema,
} from "feat/user/utils/validationSchema";
import { login } from "../actions";

const { Text } = Typography;

export const LoginForm: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const intl = useIntl();

  const loggedUser = useSelector((state) => state.currentUser.loggedUser);

  const validationSchema = object({
    username: usernameSchema(),
    password: passwordSchema(),
  });

  useEffect(() => {
    if (loggedUser) {
      history.push("/");
    }
  }, [loggedUser, history]);

  const formikCtx = useFormik({
    validationSchema,
    initialValues: {
      username: "",
      password: "",
    },
    onSubmit: async ({ username, password }) => {
      await dispatch(login({ username, password }));
    },
  });

  const { handleSubmit } = formikCtx;

  const layout = {
    labelCol: { flex: "100px" },
    wrapperCol: { flex: 1 },
  };

  return (
    <FormikProvider value={formikCtx}>
      <Helmet>
        <title>I-Sberg ERP</title>
      </Helmet>

      <Form
        className={style.root}
        onFinish={handleSubmit}
        {...layout}
        layout="horizontal"
      >
        <div className={style.loginForm}>
          <div className={style.logo}>
            <Text>ERP</Text>
          </div>

          <FormItem
            name="username"
            label={intl.formatMessage({
              id: "auth.username-placeholder",
              defaultMessage: "Login",
            })}
            required
          >
            <Input prefix={<UserOutlined />} name="username" />
          </FormItem>

          <FormItem
            name="password"
            label={intl.formatMessage({
              id: "auth.password-placeholder",
              defaultMessage: "Password",
            })}
            required
          >
            <Input prefix={<LockOutlined />} type="password" name="password" />
          </FormItem>

          <Button type="primary" htmlType="submit" className={style.button}>
            <FormattedMessage id="auth.login-button" defaultMessage="Login" />
          </Button>
        </div>
      </Form>
    </FormikProvider>
  );
};
