import { request } from "lib/api";
import { handleErrors } from "lib/handleErrors";
import { ClientOrdersResponse, QuantityInfo } from "types";
import { IPaginationReq } from "types/type-def";

// Возвращает заказы покупателей, которые оплачены или согласованы, но не отгружены
const getCustomerOrders = async ({
  page,
  pageSize,
  filter,
  term,
}: IPaginationReq) => {
  const limit = `limit=${pageSize}`;
  const offset = `&offset=${pageSize * (page - 1)}`;
  const status = `&status=${filter}`;
  const search_string = term ? `&search_string=${term}` : "";
  const params = `?${limit}${offset}${status}${search_string}`;
  const resp = await request(`/clients-orders${params}`, "GET");

  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

// Создание заказа покупателя
const createOrder = async (body: string): Promise<ClientOrdersResponse> => {
  const resp = await request("/create-order", "POST", body);

  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

// Возвращает информацию о заказе
const getOrder = async ({ key }: { key: string }) => {
  const resp = await request(`/order/${key}`, "GET");

  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

// Возвращает очередь ЗнП
const getProductionOrders = async ({
  page,
  pageSize,
  currentTechCell,
  filter,
  term,
}: IPaginationReq) => {
  const limit = `limit=${pageSize}`;
  const offset = `&offset=${pageSize * (page - 1)}`;
  const tc_id = `&tc_id=${currentTechCell}`;
  const status = `&status=${filter}`;
  const search_string = term ? `&search_string=${term}` : "";
  const params = `?${limit}${offset}${tc_id}${status}${search_string}`;
  const resp = await request(`/production-queue${params}`, "GET");

  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

// Возвращает задачу из очереди ЗнП
const getProductionOrder = async (task_id: string, tc_id: number) => {
  const params = `?tc_id=${tc_id}`;
  const resp = await request(`/production-order/${task_id}${params}`, "GET");

  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

// Move Task
const moveProductionOrder = async (body: string) => {
  const resp = await request(`/move-production-order`, "POST", body);

  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

// Возвращает историю действий заказа
const getTaskHistory = async (task_id: number, tc_id: number) => {
  const resp = await request(
    `/production-order/${task_id}/history?tc_id=${tc_id}`,
    "GET"
  );

  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

// Split task
const splitTask = async (body: string) => {
  const resp = await request(`/split-production-order`, "POST", body);

  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

const deleteTask = async (task_id: number, tc_id: number) => {
  const params = `?tc_id=${tc_id}`;
  const resp = await request(
    `/delete-production-order/${task_id}${params}`,
    "DELETE"
  );

  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

const unstaffTask = async (task_id: number) => {
  const resp = await request(`/unstaff-production-order/${task_id}`, "DELETE");

  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

// Создает спецификацию на основании характеристики полуфабриката, из которого состоит переданная спецификация
const cloneSpec = async (body: string) => {
  const resp = await request(`/clone-spec`, "POST", body);

  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

// Получение истории операции над заказом покупателя
const getClientOrderHistory = async (key: string) => {
  const resp = await request(`/order/${key}/history`, "GET");

  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

const findOrder = async (order_id: string) => {
  const resp = await request(`/orders/${order_id}`, "GET");

  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

const submitProduction = async (body: string) => {
  const resp = await request(`/submit-production`, "POST", body);

  if (!resp.ok) {
    handleErrors(resp);
  }
  return null;
};

const pauseProduction = async (body: string) => {
  const resp = await request(`/pause-production`, "PUT", body);

  if (!resp.ok) {
    handleErrors(resp);
  }
  return null;
};

// Меняет статус комплектования материалами для ЗнП
const setOrderSupplyStatus = async (task_id: number, body: string) => {
  const resp = await request(`/supply-status/${task_id}`, "PUT", body);
  if (!resp.ok) {
    handleErrors(resp);
  }
  return null;
};

// Позволяет создать ЗнП из заказа покупателя
const сreateProductionOrder = async (body: string) => {
  const resp = await request(
    "/production-order-from-customer-order",
    "POST",
    body
  );
  if (!resp.ok) {
    handleErrors(resp);
  }
  return null;
};

// Изменяет статус согласования
const changeApproveState = async (order_id: string, status: boolean) => {
  const resp = await request(`/order/${order_id}/approve-state`, "PUT", status);
  if (!resp.ok) {
    handleErrors(resp);
  }
  return null;
};

// Возвращает список компонентов для заказа на производство
const getProdComponentsList = async (
  task_id: number,
  tc_id: number,
  response_format = "json"
) => {
  const params = `tc_id=${tc_id}&response_format=${response_format}`;
  const resp = await request(
    `/production-order/${task_id}/components?${params}`,
    "GET"
  );
  if (!resp.ok) {
    handleErrors(resp);
  }
  if (response_format === "xlsx") {
    const data = await resp.blob();
    return data;
  } else {
    const data = await resp.json();
    return data;
  }
};

// Возвращает список компонентов для заказа покупателя
const getOrderComponentsList = async (
  order_id: string,
  response_format = "json"
) => {
  const params = `response_format=${response_format}`;
  const resp = await request(`/order/${order_id}/components?${params}`, "GET");
  if (!resp.ok) {
    handleErrors(resp);
  }
  if (response_format === "xlsx") {
    const data = await resp.blob();
    return data;
  } else {
    const data = await resp.json();
    return data;
  }
};

// Возвращает список компонентов для списка заказов покупателя
const getClientOrderComponentsList = async (
  filter: "all" | "approved",
  response_format = "json"
) => {
  const params = `filter=${filter}&response_format=${response_format}`;
  const resp = await request(`/client-order-components?${params}`, "GET");
  if (!resp.ok) {
    handleErrors(resp);
  }
  if (response_format === "xlsx") {
    const data = await resp.blob();
    return data;
  } else {
    const data = await resp.json();
    return data;
  }
};

// Возвращает список компонентов для списка заказов на производство
const getProdOrderComponentsList = async (
  filter: "all" | "not_in_production" | "not_stuffed",
  tc_id: number,
  response_format = "json"
) => {
  const params = `filter=${filter}&tc_id=${tc_id}&response_format=${response_format}`;
  const resp = await request(`/production-order-components?${params}`, "GET");
  if (!resp.ok) {
    handleErrors(resp);
  }
  if (response_format === "xlsx") {
    const data = await resp.blob();
    return data;
  } else {
    const data = await resp.json();
    return data;
  }
};

// Возвращает список номеклатур
const getNomenclature = async () => {
  const resp = await request("/nomenclature", "GET");

  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

// удаляем заказ покупателя
const deleteOrder = async (order_id: string) => {
  const resp = await request(`/order/${order_id}`, "DELETE");

  if (!resp.ok) {
    handleErrors(resp);
  }
  return null;
};

// Изменяет заказ (Требуются все поля)
const putOrder = async (order_id: string, body: string) => {
  const resp = await request(`/order/${order_id}`, "PUT", body);

  if (!resp.ok) {
    handleErrors(resp);
  }
  return null;
};

// Получить кол-во ЗП и ЗнП
const getQuantity = async (): Promise<QuantityInfo> => {
  const resp = await request("/get_quantity", "GET");

  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

const orders = {
  getCustomerOrders,
  createOrder,
  getOrder,
  getProductionOrders,
  moveProductionOrder,
  getTaskHistory,
  splitTask,
  deleteTask,
  unstaffTask,
  cloneSpec,
  getClientOrderHistory,
  findOrder,
  getProductionOrder,
  submitProduction,
  pauseProduction,
  setOrderSupplyStatus,
  сreateProductionOrder,
  changeApproveState,
  getProdComponentsList,
  getOrderComponentsList,
  getClientOrderComponentsList,
  getProdOrderComponentsList,
  getNomenclature,
  deleteOrder,
  putOrder,
  getQuantity,
};

export default orders;
