import { createSlice, current } from "@reduxjs/toolkit";
import { intl } from "lib/locale";

//actions
import { getWorkingSchedules, deleteWorkingSchedule } from "../actions";

//types
import { IError } from "types/type-def";
import { WorkingSchedule } from "types";

interface IInitialState {
  workingSchedules: Array<WorkingSchedule>;
  loading: boolean;
  error: IError;
}

const initialState: IInitialState = {
  workingSchedules: [],
  loading: false,
  error: null,
};

export const workingSchedulesSlice = createSlice({
  name: "workingSchedules",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // список графиков работы
    builder.addCase(getWorkingSchedules.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getWorkingSchedules.fulfilled, (state, action) => {
      state.loading = false;
      state.workingSchedules = action.payload;
    });
    builder.addCase(getWorkingSchedules.rejected, (state) => {
      state.loading = false;
      state.error = intl.formatMessage({
        id: "error.loading-error",
        defaultMessage: "An error occurred while loading",
      });
    });

    // графика работы
    builder.addCase(deleteWorkingSchedule.fulfilled, (state, action) => {
      state.loading = false;
      state.workingSchedules = current(state.workingSchedules).filter(
        (schedule) => schedule.id !== action.payload
      );
    });
  },
});
