import { useState } from "react";
import { useSelector } from "app/hooks";
import { useLocation } from "react-router-dom";

// components
import { Menu } from "antd";

// utils
import { MenuItems } from "./MenuItems";

// style
import style from "./MainMenu.module.css";

interface IProps {
  theme: "light" | "dark";
}

export const MainMenu: React.FC<IProps> = ({ theme }) => {
  const location = useLocation();
  const routes = useSelector((state) => state.routes.routes);

  const [selectedKeys, setSelectedKeys] = useState([location.pathname]);

  const onKeyClick = ({ key }: { key: string }) => setSelectedKeys([key]);

  return (
    <Menu
      theme={theme}
      mode="inline"
      onClick={onKeyClick}
      selectedKeys={selectedKeys}
      className={style.menu}
      items={MenuItems(routes)}
    />
  );
};
