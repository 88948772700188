import { useSelector, useDispatch } from "app/hooks";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

// styles
import style from "./UserMenu.module.css";

// actions
import { togglePartner } from "feat/admin/actions";

// utils
import { history } from "utils/history";

export const AdminButton: React.FC = () => {
  const dispatch = useDispatch();

  const currentUser = useSelector((state) => state.currentUser.user);

  const onChangeGroup = () => {
    history.push("/");
    dispatch(togglePartner(null));
    localStorage.removeItem("partner");
  };

  if (!currentUser?.is_super_admin) {
    return null;
  }

  return (
    <div className={style.headerBlocks}>
      <Link
        className={style.userName}
        to="/admin/partners"
        onClick={onChangeGroup}
      >
        <FormattedMessage
          id="app.main-menu-administration"
          defaultMessage="Administration"
        />
      </Link>
    </div>
  );
};
