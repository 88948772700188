/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum QRType {
    CELL = 'cell',
    PRODUCTION = 'production',
    SEMIPRODUCTION = 'semiproduction',
    PACKAGE = 'package',
}
