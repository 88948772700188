/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum ProductionOrderStage {
    NEW = 'new',
    TESTING = 'testing',
    PACKAGE = 'package',
    CNC = 'cnc',
    ASSEMBLY = 'assembly',
    UNDEFINED = 'undefined',
    SOLDERING = 'soldering',
    ENCAPSULATION = 'encapsulation',
    ENDED = 'ended',
}
