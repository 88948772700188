/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum PkgState {
    STORED = 'stored',
    SHIPPED = 'shipped',
}
