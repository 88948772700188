import { createSlice, current } from "@reduxjs/toolkit";
import { intl } from "lib/locale";

// actions
import {
  getOrder,
  getProductionOrder,
  submitProduction,
  getClientOrderHistory,
  pauseProduction,
  changeApproveState,
  setOrderSupplyStatus,
  getTaskHistory,
} from "../actions";
import { deleteShipmentDoc } from "feat/storage/actions";

// types
import {
  ProductionOrderBase,
  ProdOrderStatus,
  ClientOrdersResponse,
  LogInfo,
} from "types";
import { IError, Maybe } from "types/type-def";

interface IInitialState {
  currentOrder: Maybe<ClientOrdersResponse>;
  currentProdOrder: Maybe<ProductionOrderBase>;

  taskHistory: Array<LogInfo>;
  orderHistory: Array<LogInfo>;

  loadingApprove: boolean;
  loading: boolean;
  error: IError;
}

const initialState: IInitialState = {
  currentOrder: null,
  currentProdOrder: null,

  taskHistory: [],
  orderHistory: [],

  loadingApprove: false,
  loading: false,
  error: null,
};

export const currentOrderSlice = createSlice({
  name: "currentOrder",
  initialState,
  reducers: {
    cleanCurrentOrder: (state) => {
      state.currentOrder = null;
      state.orderHistory = [];
    },
    cleanCurrentProdOrder: (state) => {
      state.currentProdOrder = null;
      state.currentOrder = null;
      state.orderHistory = [];
    },
  },
  extraReducers: (builder) => {
    // Возвращает информацию о заказе
    builder.addCase(getOrder.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getOrder.fulfilled, (state, action) => {
      state.loading = false;
      state.currentOrder = action.payload;
    });
    builder.addCase(getOrder.rejected, (state) => {
      state.loading = false;
      state.error = intl.formatMessage({
        id: "error.loading-error",
        defaultMessage: "An error occurred while loading",
      });
    });

    // Возвращает задачу из очереди ЗнП
    builder.addCase(getProductionOrder.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getProductionOrder.fulfilled, (state, action) => {
      state.loading = false;
      state.currentProdOrder = action.payload;
    });
    builder.addCase(getProductionOrder.rejected, (state) => {
      state.loading = false;
      state.error = intl.formatMessage({
        id: "error.loading-error",
        defaultMessage: "An error occurred while loading",
      });
    });

    // запускает заказ в производство
    builder.addCase(submitProduction.fulfilled, (state, action) => {
      if (state.currentProdOrder) {
        state.currentProdOrder = {
          ...state.currentProdOrder,
          prod_status: ProdOrderStatus.IN_PRODUCTION,
        };
      }
      if (state.currentOrder) {
        const order = current(state.currentOrder);
        const taskIds = action.payload;

        const products = order?.products.map((product) =>
          product.production_orders.some((order) =>
            taskIds.includes(order.task_id)
          )
            ? {
                ...product,
                production_orders: product.production_orders.map((order) =>
                  taskIds.includes(order.task_id)
                    ? { ...order, prod_status: ProdOrderStatus.IN_PRODUCTION }
                    : order
                ),
              }
            : product
        );

        state.currentOrder = { ...order, products };
      }
    });

    // Получение истории операции над заказом покупателя
    builder.addCase(getClientOrderHistory.pending, (state) => {
      state.error = null;
    });
    builder.addCase(getClientOrderHistory.fulfilled, (state, action) => {
      state.orderHistory = action.payload;
    });
    builder.addCase(getClientOrderHistory.rejected, (state) => {
      state.error = intl.formatMessage({
        id: "error.loading-error",
        defaultMessage: "An error occurred while loading",
      });
    });

    // Возвращает истории операции над заказом на производство
    builder.addCase(getTaskHistory.pending, (state) => {
      state.error = null;
    });
    builder.addCase(getTaskHistory.fulfilled, (state, action) => {
      state.taskHistory = action.payload;
    });
    builder.addCase(getTaskHistory.rejected, (state) => {
      state.error = intl.formatMessage({
        id: "error.loading-error",
        defaultMessage: "An error occurred while loading",
      });
    });

    builder.addCase(deleteShipmentDoc.fulfilled, (state, action) => {
      state.currentOrder = {
        ...state.currentOrder,
        //@ts-expect-error
        shipment_docs: current(state.currentOrder)?.shipment_docs.filter(
          //@ts-expect-error
          (doc) => doc.guid !== action.payload
        ),
      };
    });

    // Останавливает производство
    builder.addCase(pauseProduction.fulfilled, (state, action) => {
      if (state.currentProdOrder) {
        state.currentProdOrder = {
          ...state.currentProdOrder,
          prod_status: ProdOrderStatus.NOT_IN_PRODUCTION,
        };
      }
      if (state.currentOrder) {
        const order = current(state.currentOrder);
        const task_id = action.payload;

        const products = order?.products.map((product) =>
          product.production_orders.some((order) => task_id === order.task_id)
            ? {
                ...product,
                production_orders: product.production_orders.map((order) =>
                  task_id === order.task_id
                    ? {
                        ...order,
                        prod_status: ProdOrderStatus.NOT_IN_PRODUCTION,
                      }
                    : order
                ),
              }
            : product
        );

        state.currentOrder = { ...order, products };
      }
    });

    // Изменяет статус согласования
    builder.addCase(changeApproveState.pending, (state) => {
      state.loadingApprove = true;
      state.error = null;
    });
    builder.addCase(changeApproveState.fulfilled, (state, action) => {
      state.loadingApprove = false;
      const order = current(state.currentOrder);
      //@ts-expect-error
      state.currentOrder = {
        ...order,
        approved: action.payload,
      };
    });
    builder.addCase(changeApproveState.rejected, (state) => {
      state.loadingApprove = false;
      state.error = intl.formatMessage({
        id: "error.loading-error",
        defaultMessage: "An error occurred while loading",
      });
    });

    //  Меняет статус комплектования материалами для ЗнП
    builder.addCase(setOrderSupplyStatus.fulfilled, (state, action) => {
      const { status, task_id } = action.payload;
      if (state.currentOrder) {
        const order = current(state.currentOrder);

        const products = order?.products.map((product) =>
          product.production_orders.some((order) => order.task_id === task_id)
            ? {
                ...product,
                production_orders: product.production_orders.map((order) =>
                  order.task_id === task_id
                    ? { ...order, supply_status: status }
                    : order
                ),
              }
            : product
        );

        state.currentOrder = { ...order, products };
      }

      if (state.currentProdOrder) {
        state.currentProdOrder = {
          ...current(state.currentProdOrder),
          supply_status: status,
        };
      }
    });
  },
});
