import { useEffect } from "react";
import { history } from "utils/history";
import { intl } from "lib/locale";

//components
import { Result, Button } from "antd";

export const PageNotFound: React.FC = () => {
  useEffect(() => {
    setTimeout(() => history.push("/"), 4000);
  }, []);

  const backClick = () => {
    history.push("/");
  };

  return (
    <Result
      status="404"
      title="404"
      subTitle={intl.formatMessage({
        id: "error.404",
        defaultMessage: "Sorry, the page you visited does not exist",
      })}
      extra={
        <Button type="primary" onClick={backClick}>
          {intl.formatMessage({
            id: "app.breadcrumb-home",
            defaultMessage: "Home",
          })}
        </Button>
      }
    />
  );
};
