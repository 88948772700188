import { createSlice } from "@reduxjs/toolkit";
import { intl } from "lib/locale";

// actions
import { getNomenclature } from "../actions";

// types
import { NomHierarchy } from "types";
import { IError } from "types/type-def";

interface IInitialState {
  nomenclature: Array<NomHierarchy>;
  loading: boolean;
  error: IError;
}

const initialState: IInitialState = {
  nomenclature: [],
  loading: false,
  error: null,
};

export const nomenclatureSlice = createSlice({
  name: "nomenclature",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //  Возвращает список номеклатур
    builder.addCase(getNomenclature.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getNomenclature.fulfilled, (state, action) => {
      state.loading = false;
      state.nomenclature = action.payload;
    });
    builder.addCase(getNomenclature.rejected, (state) => {
      state.loading = false;
      state.error = intl.formatMessage({
        id: "error.loading-error",
        defaultMessage: "An error occurred while loading",
      });
    });
  },
});
