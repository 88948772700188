import { getCurrentLocale } from "lib/locale";

export const request = (fetchUrl: string, method: string, body?: any) => {
  const url = `/api/v1${fetchUrl}`;

  const locale = getCurrentLocale();
  const currentPartner = localStorage.getItem("partner") ?? "";

  const options = {
    method,
    headers: {
      "Content-Type": "application/json",
      "Accept-Language": locale,
      "Partner-code": currentPartner,
      accept: "application/json",
    },
    body,
  };

  return fetch(url, options);
};
