import { handleErrors } from "lib/handleErrors";
import { request } from "lib/api";

// types
import { UserToken } from "types";

export const getToken = async ({ username, password }: UserToken) => {
  const resp = await fetch("/api/v1/token-json", {
    method: "POST",
    body: JSON.stringify({ username: username, password: password }),
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (!resp.ok) {
    handleErrors(resp);
  }

  localStorage.setItem("username", username);

  return username;
};

export const logout = async () => {
  const resp = await request(`/logout`, "DELETE");

  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

const auth = {
  getToken,
  logout,
};

export default auth;
