import { createSlice } from "@reduxjs/toolkit";
import { intl } from "lib/locale";

// actions
import { getSyncHistory } from "../actions";

// types
import { IError } from "types/type-def";
import { SystemActions } from "types";

interface IInitialState {
  syncHistory: Array<SystemActions>;
  loading: boolean;
  error: IError;
}

const initialState: IInitialState = {
  syncHistory: [],
  loading: false,
  error: null,
};

export const syncHistorySlice = createSlice({
  name: "syncHistory",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //  Получить историю синхронизации
    builder.addCase(getSyncHistory.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getSyncHistory.fulfilled, (state, action) => {
      state.loading = false;
      state.syncHistory = action.payload;
    });
    builder.addCase(getSyncHistory.rejected, (state) => {
      state.loading = false;
      state.error = intl.formatMessage({
        id: "error.loading-error",
        defaultMessage: "An error occurred while loading",
      });
    });
  },
});
