import { useSelector } from "app/hooks";
import { Redirect, Route, RouteProps } from "react-router-dom";

// components
import { withMenuLayout } from "common/components/AppLayout";

export const PrivateRoute: React.FC<RouteProps> = (props) => {
  const loggedUser = useSelector((state) => state.currentUser.loggedUser);

  if (!loggedUser) {
    return <Redirect to="/login" />;
  }

  return <Route {...props} component={withMenuLayout(props.component)} />;
};
