import { createSlice } from "@reduxjs/toolkit";
import { intl } from "lib/locale";

//actions
import { getCurrentUser } from "../actions";

//types
import { SingleUserInfo } from "types";
import { IError, Maybe } from "types/type-def";

interface IInitialState {
  user: Maybe<SingleUserInfo>;
  loggedUser: boolean;

  loading: boolean;
  error: IError;
}

const initialState: IInitialState = {
  user: null,
  loggedUser: false,

  loading: false,
  error: null,
};

export const currentUserSlice = createSlice({
  name: "currentUser",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCurrentUser.pending, (state) => {
      state.loading = true;
      state.loggedUser = false;
      state.error = null;
    });
    builder.addCase(getCurrentUser.fulfilled, (state, action) => {
      state.loading = false;
      state.loggedUser = true;
      state.user = action.payload;
    });
    builder.addCase(getCurrentUser.rejected, (state) => {
      state.loading = false;
      state.loggedUser = false;
      state.error = intl.formatMessage({
        id: "error.loading-error",
        defaultMessage: "An error occurred while loading",
      });
    });
  },
});
