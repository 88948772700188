/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum ProductState {
    STORED = 'stored',
    BEING_REPAIRED = 'being_repaired',
    SHIPPED = 'shipped',
    CONSOLIDATED = 'consolidated',
}
