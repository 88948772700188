import { createSlice } from "@reduxjs/toolkit";
import { intl } from "lib/locale";

//actions
import { getRoutes } from "../actions";

//types
import { IError } from "types/type-def";

interface IInitialState {
  routes: Record<string, boolean>;
  loading: boolean;
  error: IError;
}

const initialState: IInitialState = {
  routes: {},
  loading: false,
  error: null,
};

// Список доступных пользователю методов
export const routesSlice = createSlice({
  name: "routes",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getRoutes.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getRoutes.fulfilled, (state, action) => {
      state.loading = false;
      state.routes = action.payload;
    });
    builder.addCase(getRoutes.rejected, (state) => {
      state.loading = false;
      state.error = intl.formatMessage({
        id: "error.loading-error",
        defaultMessage: "An error occurred while loading",
      });
    });
  },
});
