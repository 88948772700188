import { Middleware } from "redux";
import { RootState } from "app/store";
import { intl } from "lib/locale";

// components
import { notification } from "antd";

// utils
import { history } from "utils/history";

// actions
import { logout } from "feat/auth/actions";

const openNotificationWithIcon = ({ message }: { message: string }) => {
  const desc =
    typeof message === "string"
      ? message
      : intl.formatMessage({
          id: "error.refresh-page",
          defaultMessage: "Refresh the page",
        });

  notification["error"]({
    message: intl.formatMessage({
      id: "error.error",
      defaultMessage: "Error",
    }),
    description: desc,
  });
};

export const errorsMiddleware: Middleware<{}, RootState> =
  (store) => (next) => (action) => {
    if (
      action?.payload?.name === "ApiError" &&
      (action.payload?.code > 401 || action.payload?.code === 400)
    ) {
      action.payload?.message.json().then((data: any) => {
        openNotificationWithIcon({
          message: data?.detail,
        });
      });

      if (action.payload?.code === 403) {
        setTimeout(() => history.goBack(), 1000);
      }
    }

    if (action?.payload?.name === "ApiError" && action.payload?.code === 401) {
      action.payload?.message.json().then((data: any) => {
        if (data?.detail === "Incorrect username or password") {
          openNotificationWithIcon({
            message: intl.formatMessage({
              id: "error.bad-login",
              defaultMessage: "Incorrect login or password",
            }),
          });
        }
      });
      //@ts-expect-error
      store.dispatch(logout());
    }

    if (action?.payload?.name === "ApiError" && action.payload?.code === 404) {
      history.push("/404");
    }

    if (action?.payload?.name === "ApiError" && action.payload?.code === 500) {
      openNotificationWithIcon({
        message: intl.formatMessage({
          id: "error.server-error",
          defaultMessage: "Internal Server Error",
        }),
      });
    }

    return next(action);
  };
