import { request } from "lib/api";
import { handleErrors } from "lib/handleErrors";

const getDaysReport = async () => {
  const resp = await request(`/reports/days-off`, "GET");

  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.blob();
  return data;
};

// Получение отчета по рабочему времени XLSX
const getWorkingTimeReport = async ({
  date_from,
  date_to,
  users,
}: {
  date_from: string;
  date_to: string;
  users: number[];
}) => {
  let searchParam = `date_from=${date_from}&date_to=${date_to}${
    users.length ? `&users=${users}` : ""
  }`;

  const resp = await request(
    `/reports/users-working-time?${searchParam}`,
    "GET"
  );

  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.blob();
  return data;
};

// Получение отчета по рабочему времени
const getUsersWorkingTime = async ({
  date_from,
  date_to,
  users,
}: {
  date_from: string;
  date_to: string;
  users: number[];
}) => {
  let searchParam = `date_from=${date_from}&date_to=${date_to}${
    users.length ? `&users=${users}` : ""
  }`;

  const resp = await request(`/users-working-time?${searchParam}`, "GET");

  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

// Получение отчета по ЗП всех пользователей Т.Я.
const getSalaryReport = async ({
  tc_id,
  from_day,
  to_day,
  user_ids,
}: {
  tc_id: number;
  from_day: string;
  to_day: string;
  user_ids: string;
}) => {
  let searchParam = `tc_id=${tc_id}&from_day=${from_day}&to_day=${to_day}${
    user_ids ? `&user_ids=${user_ids}` : ""
  }`;

  const resp = await request(`/reports/salary?${searchParam}`, "GET");

  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

// Получение отчета по ЗП всех пользователей Т.Я. в xlsx
const getSalaryReportXlsx = async ({
  tc_id,
  from_day,
  to_day,
  user_ids,
}: {
  tc_id: number;
  from_day: string;
  to_day: string;
  user_ids: string;
}) => {
  let searchParam = `tc_id=${tc_id}&from_day=${from_day}&to_day=${to_day}${
    user_ids ? `&user_ids=${user_ids}` : ""
  }`;

  const resp = await request(`/reports/salary/download?${searchParam}`, "GET");

  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.blob();
  return data;
};

const getShipmentReportXlsx = async (shipment_id: number) => {
  const resp = await request(
    `/reports/shipment/${shipment_id}/packages`,
    "GET"
  );

  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.blob();
  return data;
};

// Отчёт по выпущенной продукции
const getProducedProductsReport = async ({
  tc_id,
  from_day,
  to_day,
  split_by_day,
}: {
  tc_id: number;
  from_day: string;
  to_day: string;
  split_by_day: boolean;
}) => {
  const from = from_day ? `&from_day=${from_day}` : "";
  const to = to_day ? `&to_day=${to_day}` : "";
  const split = split_by_day ? `&split_by_day=${split_by_day}` : "";

  let searchParam = `tc_id=${tc_id}${from}${to}${split}`;

  const resp = await request(`/produced-products/report?${searchParam}`, "GET");

  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

const reports = {
  getDaysReport,
  getWorkingTimeReport,
  getUsersWorkingTime,
  getSalaryReport,
  getSalaryReportXlsx,
  getShipmentReportXlsx,
  getProducedProductsReport,
};

export default reports;
