import { createAsyncThunk } from "@reduxjs/toolkit";

// utils
import { intl } from "lib/locale";
import { history } from "utils/history";
import { message } from "antd";

export const getCells = createAsyncThunk(
  "storage/getCells",
  async (warehouse_id, { extra: { api }, rejectWithValue }) => {
    try {
      const data = await api.storage.getCells(warehouse_id);

      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

export const createCells = createAsyncThunk(
  "storage/createCells",
  async (cell_code, { extra: { api }, rejectWithValue, getState }) => {
    const warehouse_id = getState().storage.currentWarehouse;
    try {
      const data = await api.storage.createCells({ cell_code, warehouse_id });

      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

export const getWarehouses = createAsyncThunk(
  "storage/getWarehouses",
  async (tcId, { extra: { api }, rejectWithValue, getState }) => {
    const tech_cell = tcId ? tcId : getState().production.currentTechCell;
    try {
      const data = await api.storage.getWarehouses(tech_cell);

      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

export const dellCell = createAsyncThunk(
  "storage/dellCell",
  async (cell_code, { extra: { api }, rejectWithValue, getState }) => {
    const warehouse_id = getState().storage.currentWarehouse;

    try {
      await api.storage.dellCell(warehouse_id, cell_code);

      message.success(
        intl.formatMessage({
          id: "success.deleted-success",
          defaultMessage: "Deleted success",
        })
      );

      return cell_code;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

export const changeCell = createAsyncThunk(
  "storage/changeCell",
  async (
    { cell_code, warehouse_id, nom_name, fr, char, new_code },
    { extra: { api }, rejectWithValue }
  ) => {
    try {
      const data = await api.storage.changeCell(
        warehouse_id,
        cell_code,
        nom_name,
        fr,
        char,
        new_code
      );

      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

export const getCell = createAsyncThunk(
  "storage/getCell",
  async (cell_id, { extra: { api }, rejectWithValue }) => {
    try {
      const data = await api.storage.getCell(cell_id);

      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

export const getNomenclatureChars = createAsyncThunk(
  "storage/getNomenclatureChars",
  async (fr, { extra: { api }, rejectWithValue }) => {
    try {
      const data = await api.storage.getNomenclatureChars(fr);

      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

export const getNomenclatureGroup = createAsyncThunk(
  "storage/getNomenclatureGroup",
  async (fr, { extra: { api }, rejectWithValue }) => {
    try {
      const data = await api.storage.getNomenclatureGroup(fr);

      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

export const getSpacAvailChars = createAsyncThunk(
  "storage/getSpacAvailChars",
  async (spec_fr, { extra: { api }, rejectWithValue }) => {
    try {
      const data = await api.storage.getSpacAvailChars(spec_fr);

      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

export const createChar = createAsyncThunk(
  "storage/createChar",
  async (body, { extra: { api }, rejectWithValue }) => {
    try {
      const data = await api.storage.createChar(JSON.stringify(body));

      message.success(
        intl.formatMessage({
          id: "success.created-success",
          defaultMessage: "Created success",
        })
      );

      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

export const getStoredProducts = createAsyncThunk(
  "storage/getStoredProducts",
  async (_, { extra: { api }, rejectWithValue }) => {
    try {
      const data = await api.storage.getStoredProducts();

      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

export const getStoredProduct = createAsyncThunk(
  "storage/getStoredProduct",
  async (params, { extra: { api }, rejectWithValue }) => {
    const { fr, char, spec_hash_id } = params;
    try {
      const data = await api.storage.getStoredProduct(params);

      const encodedFr = window.btoa(unescape(encodeURIComponent(fr)));
      const encodedChar = window.btoa(unescape(encodeURIComponent(char)));

      const queryParams = `fr=${encodedFr}&spec_hash_id=${spec_hash_id}&char=${encodedChar}`;

      history.push(`/storage/products/product?${queryParams}`);

      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

export const freeProduction = createAsyncThunk(
  "storage/freeProduction",
  async (body, { extra: { api }, rejectWithValue }) => {
    try {
      const data = await api.storage.freeProduction(body);

      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

export const getShipments = createAsyncThunk(
  "storage/getShipments",
  async (_, { extra: { api }, rejectWithValue }) => {
    try {
      const data = await api.storage.getShipments();

      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

export const getShipmentInfo = createAsyncThunk(
  "storage/getShipmentInfo",
  async ({ order_uuid, shipment_id }, { extra: { api }, rejectWithValue }) => {
    try {
      const data = await api.storage.getShipmentInfo({
        order_uuid,
        shipment_id,
      });

      return data;
    } catch (error) {
      //console.error(error);
      return rejectWithValue(error);
    }
  }
);

export const getShipmentFile = createAsyncThunk(
  "reports/getShipmentFile",
  async (
    { shipment_doc_uuid, shipment_doc_fr },
    { extra: { api }, rejectWithValue }
  ) => {
    try {
      const data = await api.storage.getShipmentFile(shipment_doc_uuid);

      var xlsxURL = window.URL.createObjectURL(data);
      let tempLink = document.createElement("a");
      tempLink.href = xlsxURL;
      tempLink.setAttribute(
        "download",
        `${intl.formatMessage({
          id: "orders.shipments-packing-list",
          defaultMessage: "Packing list",
        })} ${shipment_doc_fr}.pdf`
      );
      tempLink.click();

      return null;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

export const createConsolidationTask = createAsyncThunk(
  "storage/createConsolidationTask",
  async (body, { extra: { api }, rejectWithValue }) => {
    try {
      const data = await api.storage.createConsolidationTask(
        JSON.stringify(body)
      );

      message.success(
        intl.formatMessage({
          id: "success.created-success",
          defaultMessage: "Created success",
        })
      );

      window.history.back();
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

export const closeShipment = createAsyncThunk(
  "storage/closeShipment",
  async (shipment_uuid, { extra: { api }, rejectWithValue }) => {
    try {
      const data = await api.storage.closeShipment(shipment_uuid);

      message.success(
        intl.formatMessage({
          id: "success.updated-success",
          defaultMessage: "Updated success",
        })
      );

      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

export const deleteConsolidationTask = createAsyncThunk(
  "storage/deleteConsolidationTask",
  async (task_group_id, { extra: { api }, rejectWithValue }) => {
    try {
      await api.storage.deleteConsolidationTask(task_group_id);

      message.success(
        intl.formatMessage({
          id: "success.deleted-success",
          defaultMessage: "Deleted success",
        })
      );

      //window.location.reload();

      return task_group_id;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

export const getPrintInfo = createAsyncThunk(
  "storage/getPrintInfo",
  async (_, { extra: { api }, rejectWithValue }) => {
    try {
      const data = await api.storage.getPrintInfo();

      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

export const updatePrintInfo = createAsyncThunk(
  "storage/updatePrintInfo",
  async (_, { extra: { api }, rejectWithValue }) => {
    try {
      const data = await api.storage.getPrintInfo();

      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

export const PrintLabels = createAsyncThunk(
  "storage/PrintLabels",
  async (
    { printer_id, template_id, body },
    { extra: { api }, rejectWithValue, dispatch }
  ) => {
    try {
      const data = await api.storage.PrintLabels({
        printer_id,
        template_id,
        body,
      });

      message.success("Документ отправлен на печать");

      dispatch(updatePrintInfo());

      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

export const getAllWarehouses = createAsyncThunk(
  "storage/getAllWarehouses",
  async (_, { extra: { api }, rejectWithValue }) => {
    try {
      const data = await api.storage.getAllWarehouses();

      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

export const createShipment = createAsyncThunk(
  "storage/createShipment",
  async (body, { extra: { api }, rejectWithValue }) => {
    try {
      const data = await api.storage.createShipment(JSON.stringify(body));

      message.success(
        intl.formatMessage({
          id: "success.created-success",
          defaultMessage: "Created success",
        })
      );

      window.history.back();
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

export const deleteShipment = createAsyncThunk(
  "storage/deleteShipment",
  async (shipment_id, { extra: { api }, rejectWithValue }) => {
    try {
      await api.storage.deleteShipment(shipment_id);

      message.success(
        intl.formatMessage({
          id: "success.deleted-success",
          defaultMessage: "Deleted success",
        })
      );

      return shipment_id;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

export const createShipmentDoc = createAsyncThunk(
  "storage/createShipmentDoc",
  async (body, { extra: { api }, rejectWithValue }) => {
    try {
      const data = await api.storage.createShipmentDoc(JSON.stringify(body));

      message.success(
        intl.formatMessage({
          id: "success.created-success",
          defaultMessage: "Created success",
        })
      );

      window.history.back();
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

export const deleteShipmentDoc = createAsyncThunk(
  "storage/deleteShipmentDoc",
  async (shipment_doc_uuid, { extra: { api }, rejectWithValue }) => {
    try {
      await api.storage.deleteShipmentDoc(shipment_doc_uuid);

      message.success(
        intl.formatMessage({
          id: "success.deleted-success",
          defaultMessage: "Deleted success",
        })
      );

      return shipment_doc_uuid;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);
