import { createSlice } from "@reduxjs/toolkit";
import { intl } from "lib/locale";

// actions
import { getUser, updateUser } from "../actions";

// types
import { IError, Maybe } from "types/type-def";
import { SingleUserInfo } from "types";

interface IInitialState {
  user: Maybe<SingleUserInfo>;
  loading: boolean;
  updating: boolean;
  error: IError;
}

const initialState: IInitialState = {
  user: null,
  updating: false,
  loading: false,
  error: null,
};

export const userSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Возвращает информацию о пользователе.
    builder.addCase(getUser.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getUser.fulfilled, (state, action) => {
      state.loading = false;
      state.user = action.payload;
    });
    builder.addCase(getUser.rejected, (state) => {
      state.loading = false;
      state.error = intl.formatMessage({
        id: "error.loading-error",
        defaultMessage: "An error occurred while loading",
      });
    });

    // Обновляет пользователя
    builder.addCase(updateUser.pending, (state) => {
      state.updating = true;
      state.error = null;
    });
    builder.addCase(updateUser.fulfilled, (state, action) => {
      state.updating = false;
      state.user = action.payload;
    });
    builder.addCase(updateUser.rejected, (state) => {
      state.updating = false;
      state.error = intl.formatMessage({
        id: "error.loading-error",
        defaultMessage: "An error occurred while loading",
      });
    });
  },
});
