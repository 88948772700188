/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum WhType {
    COMPONENTS = 'components',
    PRE_PRODUCTION = 'pre_production',
    FINISHED_PRODUCTS = 'finished_products',
    PRODUCTION = 'production',
}
