export enum IProfileSteps {
  ACCESS = "access",
  BASE = "base",
  LOCALE = "locale",
  PRODUCTION = "production",
  ROLES = "roles",
  SECURITY = "security",
}

export enum ILocale {
  RU = "ru",
  EN = "en",
}
