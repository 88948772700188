import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";

// components
import { App } from "app/App";
import { LocaleProvider } from "lib/locale";
import { WebsocketProvider } from "common/components/WebsocketProvider";

// utils
import { getCurrentUser } from "feat/user/actions";
import { history } from "utils/history";
import store from "app/store";
import reportWebVitals from "./reportWebVitals";

if (localStorage.getItem("username")) {
  store.dispatch(getCurrentUser());
}

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <Provider store={store}>
    <LocaleProvider>
      <Router history={history}>
        <HelmetProvider>
          <WebsocketProvider>
            <App />
          </WebsocketProvider>
        </HelmetProvider>
      </Router>
    </LocaleProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
