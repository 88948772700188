import { createAsyncThunk } from "@reduxjs/toolkit";

// utils
import { intl } from "lib/locale";
import { message } from "antd";

// types
import { ITCCalendar, ITCUser, ThunkAPI } from "types/type-def";
import {
  BindInfo,
  BindTask,
  InfoUserWorkDay,
  ProductionSubtaskTableRowTc,
  ProductionSubtaskTableRowUser,
} from "types";

// Получить наряд-задания
export const getPs = createAsyncThunk<
  Array<ProductionSubtaskTableRowTc>,
  undefined,
  ThunkAPI
>(
  "production/getPs",
  async (_, { extra: { api }, rejectWithValue, getState }) => {
    const tc_id = getState().orders.currentTechCell;
    try {
      const data = await api.production.getPs(tc_id);

      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

// Отвязывает задачу от работника
export const unbindTask = createAsyncThunk<BindInfo, BindInfo, ThunkAPI>(
  "production/unbindTask",
  async (tasks, { extra: { api }, rejectWithValue, getState }) => {
    const tc_id = getState().orders.currentTechCell;
    try {
      await api.production.unbindTask(JSON.stringify({ tasks, tc_id }));

      message.success(
        intl.formatMessage({
          id: "success.updated-success",
          defaultMessage: "Updated success",
        })
      );

      return tasks;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

// Начинает выполнение задачи работником
export const bindTask = createAsyncThunk<null, BindTask, ThunkAPI>(
  "production/bindTask",
  async (tasks, { extra: { api }, rejectWithValue }) => {
    try {
      await api.production.bindTask(JSON.stringify(tasks));

      message.success(
        intl.formatMessage({
          id: "success.updated-success",
          defaultMessage: "Updated success",
        })
      );

      return null;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

// Получить наряд-задания пользователя
export const getUserPs = createAsyncThunk<
  Array<ProductionSubtaskTableRowUser>,
  number,
  ThunkAPI
>(
  "production/getUserPs",
  async (user_id, { extra: { api }, rejectWithValue }) => {
    try {
      const data = await api.production.getUserPs(user_id);

      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

// Возвращает id и ФИО работников технологической ячейки
export const getTCUsers = createAsyncThunk<
  Record<string, ITCUser>,
  undefined,
  ThunkAPI
>(
  "production/getTCUsers",
  async (_, { extra: { api }, rejectWithValue, getState }) => {
    const currentTechCellId = getState().orders.currentTechCell;
    try {
      const data = await api.production.getTCUsers(currentTechCellId);

      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

// Возвращает календарь пользователей для технологической ячейки.
export const getTCCalendar = createAsyncThunk<
  ITCCalendar,
  { to_day: string; from_day: string },
  ThunkAPI
>(
  "production/getTCCalendar",
  async (
    { to_day, from_day },
    { extra: { api }, rejectWithValue, getState }
  ) => {
    const currentTechCellId = getState().orders.currentTechCell;
    try {
      const data = await api.production.getTCCalendar({
        to_day,
        from_day,
        tc_id: currentTechCellId.toString(),
      });

      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

export const setUserCalendar = createAsyncThunk<
  null,
  Array<InfoUserWorkDay>,
  ThunkAPI
>(
  "production/setUserCalendar",
  async (body, { extra: { api }, rejectWithValue }) => {
    try {
      await api.production.setUserCalendar(JSON.stringify(body));

      message.success(
        intl.formatMessage({
          id: "success.updated-success",
          defaultMessage: "Updated success",
        })
      );

      window.location.reload();

      return null;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);
