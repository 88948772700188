import React, { useContext, useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "app/hooks";

// utils
import { websocket } from "lib/websocket";

// actions
import { logout } from "feat/auth/actions";
import { ordersSlice } from "feat/orders/reducers";

// types
import { IWsSubscribe, Maybe } from "types/type-def";

const { setProductionOrders, setCustomerOrders } = ordersSlice.actions;

type IMsg = {
  sub: string;
  content: any;
};

interface IProps {
  children: React.ReactNode;
}

const WebsocketProviderCtx = React.createContext({
  wsSend: (arg: IWsSubscribe) => {},
  // wsClose: () => {},
});

const messageHandlerRecord: Record<string, any> = {
  "/api/v1/": setProductionOrders,
  "/api/v1/clients-orders": setCustomerOrders,
};

export const WebsocketProvider: React.FC<IProps> = ({ children }) => {
  const dispatch = useDispatch();
  const loggedUser = useSelector((state) => state.currentUser.loggedUser);
  const currentPartner = useSelector((state) => state.partners.currentPartner);
  const currentPartnerId = currentPartner?.id;
  const isLocalhost = window.location.hostname === "localhost";

  const [ws, setWs] = useState<Maybe<WebSocket>>(null);

  const connect = useCallback(() => {
    const ws = websocket(currentPartnerId);
    setWs(ws);

    ws.onmessage = (e) => {
      const msg: IMsg = JSON.parse(e.data);
      const handler = messageHandlerRecord[msg.sub];
      dispatch(handler(msg.content));
    };

    ws.onclose = (e) => {
      if (e.code !== 1000 && e.code !== 1001 && e.code !== 1005) {
        if (e.code === 4004) {
          dispatch(logout());
        } else {
          setTimeout(() => {
            connect();
          }, 1000);
        }
      }
    };

    ws.onerror = (e) => {
      ws.close();
    };
  }, [currentPartnerId, dispatch]);

  useEffect(() => {
    if (loggedUser && currentPartnerId && !isLocalhost) {
      connect();
    }
  }, [connect, loggedUser, currentPartnerId, isLocalhost]);

  useEffect(() => {
    if ((!currentPartnerId || !loggedUser) && ws) {
      ws.close();
    }
  }, [currentPartnerId, ws, loggedUser]);

  const wsSend = (arg: IWsSubscribe) => {
    if (ws) {
      try {
        ws.send(JSON.stringify(arg));
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <WebsocketProviderCtx.Provider
      value={{
        wsSend: wsSend,
      }}
    >
      {children}
    </WebsocketProviderCtx.Provider>
  );
};

export const useWebsocket = () => useContext(WebsocketProviderCtx);
