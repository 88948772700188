import { createSlice } from "@reduxjs/toolkit";
import { intl } from "lib/locale";

// actions
import { getRoles, getScopes } from "../actions";

// types
import { IError, IRole } from "types/type-def";
import { Scope } from "types";

interface IInitialState {
  roles: Array<IRole>;
  scopes: Array<Scope>;
  loading: boolean;
  error: IError;
}

const initialState: IInitialState = {
  roles: [],
  scopes: [],
  loading: false,
  error: null,
};

export const rolesSlice = createSlice({
  name: "roles",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Возвращает все возможные роли в формате {"<i​d>": "<Название>"}
    builder.addCase(getRoles.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getRoles.fulfilled, (state, action) => {
      state.loading = false;
      state.roles = action.payload;
    });
    builder.addCase(getRoles.rejected, (state) => {
      state.loading = false;
      state.error = intl.formatMessage({
        id: "error.loading-error",
        defaultMessage: "An error occurred while loading",
      });
    });

    //
    builder.addCase(getScopes.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getScopes.fulfilled, (state, action) => {
      state.loading = false;
      state.scopes = action.payload;
    });
    builder.addCase(getScopes.rejected, (state) => {
      state.loading = false;
      state.error = intl.formatMessage({
        id: "error.loading-error",
        defaultMessage: "An error occurred while loading",
      });
    });
  },
});
